export default {
    data: () => ({
        /*
        |--------------------------------------------------------------------------
        | schemas
        |--------------------------------------------------------------------------
        |
        | All CRUDs are going to use ContentForm.vue component for creating and
        | editing records. It uses vue-form-generator package to generate all
        | necessary form inputs and elements needed from certain schema that is
        | to be provided in this file, below. Please, consult this link
        |
        | https://vue-generators.gitbook.io/vue-generators/fields
        |
        | if you need help with specific input types.
        |
        */

        schemas: {
            phones: {
                groups: [
                    {
                        legend: 'Phone Details',
                        fields: [
                            {
                                type: 'input',
                                inputType: 'text',
                                label: 'Number',
                                model: 'number',
                                id: 'number',
                                placeholder: 'Phone number',
                                featured: true,
                                required: true
                            }
                        ]
                    }
                ]
            },
            request_types: {
                groups: [
                    {
                        legend: 'Request type Details',
                        fields: [
                            {
                                type: 'input',
                                inputType: 'text',
                                label: 'Type',
                                model: 'name',
                                id: 'name',
                                placeholder: 'Request type',
                                featured: true,
                                required: true
                            }
                        ]
                    }
                ]
            },
            message_logs: {
                groups: [
                    {
                        legend: 'Message Log Details',
                        fields: [
                            {
                                type: 'input',
                                inputType: 'text',
                                label: 'From',
                                model: 'from',
                                id: 'from',
                                placeholder: 'From',
                                disabled: true,
                            },
                            {
                                type: 'input',
                                inputType: 'text',
                                label: 'To',
                                model: 'to',
                                id: 'to',
                                placeholder: 'To',
                                disabled: true,
                            },
                            {
                                type: 'input',
                                inputType: 'text',
                                label: 'Text',
                                model: 'text',
                                id: 'text',
                                placeholder: 'Text',
                                disabled: true,
                            },
                            {
                                type: 'input',
                                inputType: 'text',
                                label: 'Code',
                                model: 'code',
                                id: 'code',
                                placeholder: 'Code',
                                disabled: true,
                            },
                            {
                                type: 'input',
                                inputType: 'text',
                                label: 'Request Type',
                                model: 'request_type.name',
                                id: 'request_type',
                                placeholder: 'Request Type',
                                disabled: true,
                            },
                            {
                                type: 'input',
                                inputType: 'text',
                                label: 'Company',
                                model: 'company.name',
                                id: 'company',
                                placeholder: 'Company',
                                disabled: true,
                            },
                            {
                                type: 'input',
                                inputType: 'text',
                                label: 'Phone',
                                model: 'phone.number',
                                id: 'phone',
                                placeholder: 'Phone',
                                disabled: true,
                            },
                            {
                                type: 'input',
                                inputType: 'text',
                                label: 'Keyword',
                                model: 'keyword.name',
                                id: 'keyword',
                                placeholder: 'Keyword',
                                disabled: true,
                            },
                        ]
                    }
                ]
            },
            users: {
                groups: [
                    {
                        legend: 'User Details',
                        fields: [
                            {
                                type: 'input',
                                inputType: 'text',
                                label: 'Name',
                                model: 'name',
                                id: 'name',
                                placeholder: 'Name',
                                featured: true,
                                required: true
                            },
                            {
                                type: 'input',
                                inputType: 'email',
                                label: 'Email',
                                model: 'email',
                                id: 'email',
                                placeholder: 'Email',
                                featured: true,
                                required: true
                            },
                            {
                                type: 'input',
                                inputType: 'password',
                                label: 'Password',
                                model: 'password',
                                id: 'password',
                                placeholder: 'Password',
                                featured: true,
                                required: true
                            },
                            {
                                type: 'input',
                                inputType: 'password',
                                label: 'Confirm password',
                                model: 'password_confirmation',
                                id: 'password_confirmation',
                                placeholder: 'Confirm password',
                                featured: true,
                                required: true
                            },
                        ]
                    },
                    {
                        legend: 'Roles',
                        fields: [
                            {
                                type: "checklist",
                                label: "Roles",
                                model: "roles",
                                listBox: true,
                                values: function() {
                                    return this.$root.$children[0].preloaded.roles
                                },
                                checklistOptions: {
                                    value: "id",
                                    name: "name"
                                }
                            }
                        ]
                    }
                ]
            },
            keywords: {
                groups: [
                    {
                        legend: 'Keyword details',
                        fields: [
                            {
                                type: 'input',
                                inputType: 'text',
                                label: 'Name',
                                model: 'name',
                                id: 'name',
                                placeholder: 'Keyword name',
                                featured: true,
                                required: true
                            },
                            {
                                type: 'input',
                                inputType: 'number',
                                label: 'Value',
                                model: 'value',
                                id: 'value',
                                placeholder: 'Keyword value',
                                featured: true,
                                required: true
                            }
                        ]
                    },
                    {
                        legend: 'Company',
                        fields: [
                            {
                                type: "select",
                                label: "Select company",
                                model: "company_id",
                                required: true,
                                values: function () {
                                    return this.$root.$children[0].preloaded.companies
                                    // return this.$root.$children[0].$options.propsData.options.companies
                                },
                                // default: "en-US",
                                // validator: validators.required
                            }
                        ]
                    },
                            // {
                            //     type: "vueMultiSelect",
                            //     model: "company_id",
                            //     label: "Libraries",
                            //     placeholder: "Select your favorite library",
                            //     required: true,
                            //     // validator: validators.required,
                            //     selectOptions: {
                            //         multiple: false,
                            //         key: "id",
                            //         label: "name",
                            //         searchable: true,
                            //         loading: true,
                            //         limit: 2
                            //     },
                            //     values: function() {
                            //         return this.$root.$children[0].companiesList
                            //     }
                            // },
                    // },
                    {
                        legend: 'Phones',
                        fields: [
                            {
                                type: "checklist",
                                label: "Phones",
                                model: "phones",
                                listBox: true,
                                values: function() {
                                    return this.$root.$children[0].preloaded.phones
                                },
                                checklistOptions: {
                                    value: "id",
                                    name: "number"
                                }
                            }
                        ]
                    }
                ]
            },
            companies: {
                groups: [
                    {
                        legend: 'Company Details',
                        fields: [
                            {
                                type: 'input',
                                inputType: 'text',
                                label: 'Name',
                                model: 'name',
                                id: 'name',
                                placeholder: 'Enter name',
                                featured: true,
                                required: true
                            },
                            {
                                type: 'input',
                                inputType: 'text',
                                label: 'Address',
                                model: 'address',
                                id: 'address',
                                placeholder: 'Enter address',
                                featured: true,
                                required: true
                            },
                            {
                                type: 'input',
                                inputType: 'text',
                                label: 'City',
                                model: 'city',
                                id: 'city',
                                placeholder: 'Enter city',
                                featured: true,
                                required: true
                            },
                            {
                                type: 'input',
                                inputType: 'text',
                                label: 'Country',
                                model: 'country',
                                id: 'country',
                                placeholder: 'Enter country',
                                featured: true,
                                required: true
                            }
                        ]
                    }
                ]
            },
            roles: {
                groups: [
                    {
                        legend: 'Role Details',
                        fields: [
                            {
                                type: 'input',
                                inputType: 'text',
                                label: 'Name',
                                model: 'name',
                                id: 'name',
                                placeholder: 'Enter name',
                                featured: true,
                                required: true
                            },
                        ]
                    },
                    {
                        legend: 'Permissions',
                        fields: [
                            {
                                type: "checklist",
                                label: "Permissions",
                                model: "permissions",
                                listBox: true,
                                values: function() {
                                    console.log(this.$root.$children[0])
                                    return this.$root.$children[0].permissions
                                },
                                checklistOptions: {
                                    value: "id"
                                }
                            }
                        ]
                    }
                ]
            }
        },
    })
}