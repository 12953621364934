export default {
    methods: {
        /*
        |--------------------------------------------------------------------------
        | getResults
        |--------------------------------------------------------------------------
        |
        | This method is invoked when Index components for different models
        | are mounted. It will fetch raw resources from Laravel endpoint,
        | wrapped in Laravel pagination object. Use pagination.data to access data.
        |
        */
        getResults(page = 1) {
            this.$http.get(`/${this.content}/raw?page=${page}&sort=${this.direction + this.sortBy}&filter[search_term]=${this.searchTerm}&include=${this.relations}`)
                .then(response => {
                    this.pagination = response.data
                });
        },
        /*
        |--------------------------------------------------------------------------
        | getMessages
        |--------------------------------------------------------------------------
        |
        | This method is invoked when Index components for different models
        | are mounted. It will fetch raw resources from Laravel endpoint,
        | wrapped in Laravel pagination object. Use pagination.data to access data.
        |
        */
        getMessages(page = 1) {
            this.$http.get(`/message-logs/raw?page=${page}` +
                `&sort=${this.direction + this.sortBy}` +
                `&filter[request_type_id]=${this.requestTypeId}` +
                `&filter[company_id]=${this.company}` +
                `${this.providerFilter}` +
                `${this.fromFilter}` +
                `${this.dateBetweenFilter}` +
                `${this.keywordFilter}` +
                `${this.statusFilter}` +
                `${this.notGeneratedFilter}` +
                `&include=${this.relations}`)
                .then(response => {
                    this.pagination = response.data
                });
        },
        /*
        |--------------------------------------------------------------------------
        | getPayments
        |--------------------------------------------------------------------------
        |
        | This method is invoked when Index components for different models
        | are mounted. It will fetch raw resources from Laravel endpoint,
        | wrapped in Laravel pagination object. Use pagination.data to access data.
        |
        */
        getPayments(page = 1) {
            this.$http.get(`/payments/raw?page=${page}` +
                `&sort=${this.direction + this.sortBy}` +
                // `&filter[search_term]=${this.searchTerm}` +
                `${this.fromFilter}` +
                // `${this.dateBetweenTodayFilter}` +
                `${this.dateBetweenFilter}` +
                `${this.statusFilter}`)
                .then(response => {
                    this.pagination = response.data
                });
        },
        getCustomersNeedingHelp(page = 1) {
            this.$http.get(`/customer-help/raw?page=${page}` +
                `&filter[company_id]=${this.company}`+
                `&filter[failed_attempts]=${this.failedAttempts}`)
                .then(response => {
                    this.pagination = response.data
                });
        },
        /*
        |--------------------------------------------------------------------------
        | getActiveSubscribers
        |--------------------------------------------------------------------------
        |
        | This method is invoked when Index components for different models
        | are mounted. It will fetch raw resources from Laravel endpoint,
        | wrapped in Laravel pagination object. Use pagination.data to access data.
        |
        */
        getActiveSubscribers(page = 1) {
            this.$http.get(`/subscribers/raw?page=${page}` +
                `&sort=${this.direction + this.sortBy}` +
                `&filter[company_id]=${this.company}` +
                `&filter[search_term]=${this.number}` +
                // `${this.fromFilter}` +
                `${this.dateBetweenFilter}` +
                `${this.statusIdFilter}`)
                .then(response => {
                    this.pagination = response.data
                });
        },

        getCustomers(page = 1) {
            this.$http.get(`/customers/raw?page=${page}` +
                `&sort=${this.direction + this.sortBy}` +
                `&filter[company_id]=${this.company}` +
                `&filter[search_term]=${this.number}` +
                `&filter[search_term]=${this.number}` +
                // `${this.fromFilter}` +
                `${this.dateBetweenFilter}` +
                `${this.statusIdFilter}` +
                `&include=${this.relations}`)
                .then(response => {
                    this.pagination = response.data
                }).catch(err => {
            })
        },

        getNumberSubscriptionDetails(page = 1) {
            this.$http.get(`/subscribers/raw?page=${page}` +
                `&sort=${this.direction + this.sortBy}` +
                `&filter[company_id]=${this.company}` +
                `&filter[search_term]=${this.number}` +
                // `${this.fromFilter}` +
                // `${this.dateBetweenFilter}` +
                `${this.statusIdFilter}`)
                .then(response => {
                    this.pagination = response.data
                });
        },
        /*
        |--------------------------------------------------------------------------
        | getProviders
        |--------------------------------------------------------------------------
        |
        | Get providers for eventual dropdowns or select lists
        |
        */
        getProviders() {
            this.$http.get(`/providers/raw`)
                .then(response => {
                    this.providers = response.data
                });
        },
        /*
        |--------------------------------------------------------------------------
        | getKeywords
        |--------------------------------------------------------------------------
        |
        | Get keywords for eventual dropdowns or select lists.
        | Unshift null object in array, which is used for 'All' option in
        | CustomDropdown component
        |
        */
        getKeywords() {
            this.$http.get(`/keywords/raw?filter[company_id]=${this.company}`)
                .then(response => {
                    this.keywords = response.data
                    this.keywords.unshift(null)
                });
        },
        /*
        |--------------------------------------------------------------------------
        | numberHistory
        |--------------------------------------------------------------------------
        |
        | Opens details by sender number in new tab. Care with usage that you have
        | company variable in caller component, since method uses this.company
        |
        */
        numberHistory(message) {
            window.open(`/dashboard/${this.company}/details-number/${message.from}`,
                '_blank' // <- This is what makes it open in a new window.
            );
        },
        numberSubscriptionHistory(message) {
            window.open(`/dashboard/${this.company}/subscription-details-number/${message.customer.phone}`,
                '_blank' // <- This is what makes it open in a new window.
            );
        },
        /*
        |--------------------------------------------------------------------------
        | generateCode
        |--------------------------------------------------------------------------
        |
        | Makes call to generate code if payment is successful but code isn't generated
        | for some reason.
        |
        */
        generateCode(message) {
            this.$http.get(`/dashboard/${this.company}/${message.provider_id}/generate-code/${message.id}`)
                .then(response => {
                    this.$snotify.success('Code generated successfully')
                    this.getMessages()
                });
        },
        /*
        |--------------------------------------------------------------------------
        | loadRelations
        |--------------------------------------------------------------------------
        |
        | Called when page is created, so it will check includes prop and push
        | all elements from that prop to relations array that is used for
        | fetching data
        |
        */
        loadRelations() {
            if (this.includes) {
                this.includes.forEach(relation => {
                    this.relations.push(relation)
                })
            }
        },
        /*
        |--------------------------------------------------------------------------
        | mapRelations
        |--------------------------------------------------------------------------
        |
        | Some of the relations will be loaded with certain model, and are loaded
        | as whole object, but for the form fields to know which are already
        | selected we need only IDs, so this function maps this array of relations
        | to array of IDs of these related models
        |
        */
        mapRelations() {
            if (this.model.id) {
                this.includes.forEach(element => {
                    if (this.model[element] && Array.isArray(this.model[element])) {
                        this.model[element] = this.model[element].map(item => item.id)
                    }
                })
            }
        },
        /*
        |--------------------------------------------------------------------------
        | sortByAttribute
        |--------------------------------------------------------------------------
        |
        | Invoked on click in table header arrow (up or down) to sort data by
        | that attribute. It will change direction or sortBy variable and make new
        | axios call that will return data sorted by that attribute. If it is
        | already sorted by that attribute, it will just change direction.
        |
        */
        sortByAttribute(sort) {
            sort = sort.replace('.', '_')

            if(sort === this.sortBy) {
                this.direction = this.direction === '' ? '-' : ''
            }

            this.sortBy = sort;
            this.getResults()
        },
        /*
        |--------------------------------------------------------------------------
        | errorMessage
        |--------------------------------------------------------------------------
        |
        | Used to get Laravel error messages from response.
        |
        */
        errorMessage(error) {
            if (error.response.data.status) {
                return error.response.data.status
            }

            let errorResponse = error.response.data.errors ? error.response.data.errors : error.response.data

            let errors = []
            for (let key in errorResponse) {
                if(key === 'message'){
                    errors.push(errorResponse[key])
                }
                else if (key !== 'exception' && key !== 'file' && key !== 'line' && key !== 'trace') {
                    for (let i = 0; i < errorResponse[key].length; i++){
                        errors.push(errorResponse[key][i])
                    }
                }
            }

            return errors
        },
        /*
        |--------------------------------------------------------------------------
        | getTimestamp
        |--------------------------------------------------------------------------
        |
        | Used to format timestamp string.
        |
        */
        getTimestamp(date) {
            return new Date(date).toLocaleString()
        },
        /*
        |--------------------------------------------------------------------------
        | capitalize
        |--------------------------------------------------------------------------
        |
        | Used to make first letter uppercase.
        |
        */
        capitalize(word) {
            return _.capitalize(word)
        },
        /*
        |--------------------------------------------------------------------------
        | percentageColor
        |--------------------------------------------------------------------------
        |
        | Used to return class for coloring numbers and arrow, whether the
        | percentage number is positive or not
        |
        */
        percentageColor(percentage) {
            switch(true) {
                case percentage > 0:
                    return 'green'
                case percentage < 0:
                    return 'red'
                default:
                    return ''
            }
        },
        /*
        |--------------------------------------------------------------------------
        | formatValue
        |--------------------------------------------------------------------------
        |
        | Used to format table cell value, if it needs any kind of manipulating.
        | Essentially used for getting certain attribute if column is object
        | instead of primitive type.
        |
        */
        percentageArrow(percentage) {
            switch(true) {
                case percentage > 0:
                    return 'fa-arrow-up'
                case percentage < 0:
                    return 'fa-arrow-down'
                default:
                    return ''
            }
        },
        /*
        |--------------------------------------------------------------------------
        | providerLogo
        |--------------------------------------------------------------------------
        |
        | Used to give wanted logo image for certain provider
        |
        */
        providerLogo(provider) {
            switch(provider) {
                case 1:
                    return '/bhtelecom.svg'
                case 2:
                    return '/eronet.svg'
                case 3:
                    return '/mtel.svg'
                default:
                    return ''
            }
        },
        /*
        |--------------------------------------------------------------------------
        | formatServiceResponse
        |--------------------------------------------------------------------------
        |
        | Format service response to get only description for display
        |
        */
        formatServiceResponse(serviceResponse) {
            if (serviceResponse === null) {
                return 'No response'
            }

            try {
                return JSON.parse(serviceResponse.replace(/\\/g, '')).description;
            } catch (e) {
                return serviceResponse;
            }
        },
        /*
        |--------------------------------------------------------------------------
        | formatValue
        |--------------------------------------------------------------------------
        |
        | Used to format table cell value, if it needs any kind of manipulating.
        | Essentially used for getting certain attribute if column is object
        | instead of primitive type.
        |
        */
        formatValue(item, column) {
            let attributeObject = column.substr(0, column.indexOf('.'))
            let attributeName = column.substr(column.indexOf('.') + 1)

            if (attributeObject && typeof item[attributeObject] === 'object' && item[attributeObject] !== null) {

                if (Array.isArray(item[attributeObject])) {
                    return item[attributeObject].map(element => element[attributeName]).join(', ')
                }
                return item[attributeObject][attributeName]
            }

            return item[column]
        },
        /*
        |--------------------------------------------------------------------------
        | editRecord
        |--------------------------------------------------------------------------
        |
        | Redirect to wanted edit view for certain record. Used for every
        | component, so just give it path of the url you want to go to
        | i.e. users/1/edit
        |
        */
        editRecord(id){
            window.location = `${this.content}/${id}/edit?include=${this.includes}`;
        },
        /*
        |--------------------------------------------------------------------------
        | deleteRecord
        |--------------------------------------------------------------------------
        |
        | Make delete axios call to the Laravel endpoint to delete certain
        | record. It will prompt you, and after clicked yes, it will delete
        | delete record from both frontend table, and delete instance in database.
        |
        */
        deleteRecord(id, index){
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true
            }).then((result) => {
                if(result.value) {
                    this.$http.delete(`${this.content}/${id}`)
                        .then(response => {
                            this.$snotify.success(response.data.status)
                            this.pagination.data.splice(index, 1);

                        })
                        .catch(error => {
                            this.$snotify.error(this.errorMessage(error))
                        });
                }
            })
        },
        /*
        |--------------------------------------------------------------------------
        | submitForm
        |--------------------------------------------------------------------------
        |
        | Make axios call to the Laravel endpoint to submit form for specific
        | model.
        |
        */
        submitForm(){
            let model = {}

            for (let key in this.model) {
                model[key] = this.model[key]
            }

            if (model.id) {
                this.$http.put(`/${this.content}/${model.id}`, model)
                    .then(response => {
                        this.$snotify.success(response.data.status)

                    })
                    .catch(error => {
                        this.$snotify.error(this.errorMessage(error))
                    });
            } else {
                this.$http.post(`/${this.content}`, model)
                    .then(response => {
                        this.$snotify.success(response.data.status)

                    })
                    .catch(error => {
                        this.$snotify.error(this.errorMessage(error))
                    });
            }
        }
    }
}
