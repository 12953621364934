<template>
    <div class="dashboard-wrapper">
        <div class="label-row">
            <div class="filter-buttons flex-column">
                <h4 class="big-title">{{ errorName }}</h4>

                <span class="current-period">{{ currentPeriod }}</span>
            </div>
            <div class="filter-buttons">
                <a href="" @click="printWindow()">Print</a>
            </div>
        </div>

        <div>
            <div class="label-row mt-5">
                <div class="refresh-button">
                    <h4 class="big-title">Transaction History</h4>
                    <button class="btn btn-outline-secondary" @click="getData">Refresh</button>
                </div>

                <div class="filter-buttons">
                    <label>
                        <input class="form-control" type="text" v-model="number" @input="debouncedGetResults" placeholder="Search number...">
                    </label>

<!--                    <custom-dropdown-->
<!--                            name="provider"-->
<!--                            :options="providers"-->
<!--                            v-model="provider"-->
<!--                            @changedSelection="getData"-->
<!--                    ></custom-dropdown>-->

                    <date-picker v-model="myDate" @updateDateRange="updateValues"></date-picker>
                </div>
            </div>

            <table class="messages-table">
                <tr>
                    <th>Phone number</th>
                    <th>Count</th>
                    <th>Timestamps</th>
                </tr>
                <tr v-if="!data">
                    <th colspan="6">
                        <span class="loader-icon"></span>
                    </th>
                </tr>
                <tr v-if="data" v-for="(error, index) in data" :key="index">
                    <td>{{ index }}</td>
                    <td>{{ error.count }}</td>
                    <td>{{ error.dates }}</td>
                </tr>
            </table>

<!--            <pagination :data="pagination" @pagination-change-page="getData" align="center" :limit="3"></pagination>-->
        </div>
    </div>
</template>

<script>
    import filters from '../../mixins/filters.js'
    export default {
        props: ['company'],
        mixins: [filters],
        data: function () {
            return {
                details: null,
                data: [],
                sortBy: 'created_at',
                direction: '-', // - is for desc, empty is for asc order

                requestTypeId: 2, // sms that are sent from mobile to our application

                // providers: [],

                // Filters
                // provider: {
                //     id: 1,
                //     name: 'BHTelecom'
                // },
                number: '',
                error: '',
                errorName: '',
                currentPeriod: '',

                // date range picker
                myDate: {
                    startDate: moment().startOf('month').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                },
            }
        },
        methods: {
            updateValues() {
                this.currentPeriod = `${this.myDate.startDate} - ${this.myDate.endDate}`
                this.getData()
            },
            getData(page = 1) {
                if (typeof page !== 'number')
                    page = 1

                this.$http.get(`/dashboard/${this.company}/error-numbers/data?page=${page}` +
                    `&filter[company_id]=${this.company}` +
                    `&${this.error}` +
                    `${this.providerFilter}` +
                    `${this.fromFilter}` +
                    `${this.dateBetweenFilter}`)
                    .then(response => {
                        this.data = response.data
                    });
            },
            printWindow() {
                window.print()
            }
        },
        mounted() {
            this.getData()

        },
        created() {
            this.error = window.location.href.substr(window.location.href.indexOf('?description') + 1)
            this.errorName = unescape(this.error.substr(12))
            // this.getProviders()
            this.debouncedGetResults = _.debounce(this.getData, 500)
            this.currentPeriod = moment(this.myDate.startDate).format('DD.MM.YYYY') + '-' +  moment(this.myDate.endDate).format('DD.MM.YYYY')
        }
    }
</script>

<style scoped>
    .refresh-button {
        display: flex;
    }

    .refresh-button button {
        width: 130px;
        height: 32px;
        padding: 0;
        margin-left: 10px;
        box-shadow: none;
    }

    .filter-buttons {
        display: flex;
        justify-content: space-around;
    }

    .filter-buttons div {
        padding: 0 10px;
    }

    .current-period {
        margin-left: 0;
    }

    table {
        width: 100%;
    }

    th {
        font-family: Roboto, Bold, sans-serif;
        background: #F5F6FA;
        padding: 15px 20px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        text-align: left;
        letter-spacing: 0.41px;
        color: #87878A;
        opacity: 1;
    }

    td {
        padding: 5px 20px;
        font: Normal 15px/20px Source Sans Pro;
        color: #4D4F5C;
        cursor: pointer;
    }

    tr td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .pagination {
        margin-top: 20px;
    }

    .pagination >>> .page-item .page-link {
        color: #87878A;
        box-shadow: none;
    }

    .pagination >>> .page-item.active .page-link {
        background-color: #ebecf0;
        border: 1px solid #dee2e6;
    }
</style>