<template>
    <div class="card shadow-sm">
        <div class="card-header">
            <h5 class="d-inline"><strong>{{ capitalize(content.replace('_', ' ')) }}</strong></h5>
            <input type="text" class="form-control w-25 d-inline" placeholder=" Search..." v-model="searchTerm" @input="debouncedGetResults">
            <a :href="`/${content}/create`"><button class="link-style btn btn-sm btn-success float-right"><i class="fas fa-plus mr-1"></i>Create new</button></a>
        </div>

        <div class="card-body">
            <div>
                <vue-snotify></vue-snotify>
                <table class="table table-hover mt-4">
                    <thead>
                    <tr>
                        <th v-for="(value, name) in columns" :key="name" @click="sortByAttribute(name)">{{ value }}<span class="sort"><i class="fas fa-sort-up"></i><i class="fas fa-sort-down"></i></span></th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="!pagination.data">
                        <th colspan="6">
                            <span class="loader-icon"></span>
                        </th>
                    </tr>
                    <tr v-if="pagination.data" v-for="(item, index) in pagination.data" :key="item.id">
                        <th v-for="(key, column) in columns" :key="column">{{ formatValue(item, column) }}</th>
                        <th>
                            <div class="btn-group" role="group" aria-label="Action Buttons">
                                <button @click="editRecord(item.id)" class="btn btn-sm btn-primary mr-1">
                                    <i class="fas fa-pencil-alt mr-1"></i>
                                    Edit
                                </button>
                                <button @click="deleteRecord(item.id, index)" class="btn btn-sm btn-danger">
                                    <i class="fas fa-trash mr-1"></i>
                                    Delete
                                </button>
                            </div>
                        </th>
                    </tr>
                    </tbody>
                </table>

                <pagination :data="pagination" @pagination-change-page="getResults" align="center"></pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['content', 'columns', 'includes'],
        data: ()=> ({
            pagination: {},
            relations: [],
            searchTerm: '',
            sortBy: 'created_at',
            direction: '-' // - is for desc, empty is for asc order
        }),
        mounted() {
            this.getResults()
        },
        methods: {
            // methods
        },
        created() {
            this.loadRelations()
            this.debouncedGetResults = _.debounce(this.getResults, 500)
        }
    }
</script>

<style scoped>

</style>