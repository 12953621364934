<template>
    <div>
        <div class="stats-card stats-card-loading" v-if="loading">
            <span class="loader-icon"></span>
        </div>

        <div class="stats-card" v-else-if="stats && (Object.values(stats)[0].value || Object.values(stats)[1].value || Object.values(stats)[2].value)">
            <div class="stats-column" v-for="stat in stats">
                <div class="stat-title">{{ stat.title }}</div>
                <div class="stat-value">{{ stat.value }}</div>
                <div>{{ stat.description }}</div>
            </div>
        </div>

        <div class="stats-card empty" v-else>
            No stats in given period
        </div>
    </div>
</template>

<script>
    export default {
        name: "StatsCard",
        props: ['stats', 'loading']
    }
</script>

<style scoped>
    .stats-card {
        background: #fff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 305px;
        height: 178px;
        box-shadow: 0 2px 10px #00000033;
        border-radius: 10px;
    }

    .stats-card.empty {
        justify-content: center;
        align-items: center;
    }

    .stats-card-loading {
        align-items: center;
        justify-content: center;
    }

    .loader-icon {
        top: 0;
        left: 0;
    }

    .stats-column {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Mosk 600', sans-serif;
    }

    .stats-column:hover {
        background: whitesmoke;
    }

    .stat-title {
        font-size: 15px;
        color: #A7A7A7;
    }

    .stat-value {
        font-size: 22px;
        color: #419AFF;
    }
</style>