<template>
    <div class="dashboard-wrapper">
        <div class="label-row">
            <div class="filter-buttons">
                <h4 class="big-title">Customers</h4>
                <span class="current-period">{{ currentPeriod }}</span>
            </div>
        </div>

        <div v-if="packages.length">
            <hr>
            <p>Manually send sms to customer</p>
            <div style="display: flex">
                <label style="margin-right: 5px; margin-bottom: 0">
                    <input class="form-control" type="text" v-model="customerNumber" @keypress="isNumber()" placeholder="Customer number...">
                </label>
                <custom-dropdown
                        name="status"
                        :options="packages"
                        v-model="package"
                ></custom-dropdown>
                <button @click="sendSms" style="z-index: 100; margin-left: 5px" class="btn btn-outline-danger">Send Sms</button>
            </div>
            <p style="margin-top: 10px" v-if="typeof package.name !== 'undefined'"><strong>SMS Preview:</strong> Molimo da jos jednom potvrdite produzenje sa odgovorom: {{package.name}}</p>
        </div>

        <customers-table :pagination="pagination" @sendSms="sendSms" @numberHistory="numberSubscriptionHistory" @refreshTable="updateValues" @pageChanged="getCustomers">
            <label>
                <input class="form-control" type="text" v-model="number" @input="debouncedGetResults" placeholder="Search number...">
            </label>

            <date-picker v-model="myDate" @updateDateRange="updateValues"></date-picker>
        </customers-table>

    </div>
</template>

<script>
    import filters from "../../mixins/filters";

    export default {
        name: "Customers",
        props: {
            company: {
                type: Number
            }
        },
        mixins: [filters],
        data() {
            return {
                customerNumber: '',
                packages: [],
                package: {},
                pagination: {},
                sortBy: 'created_at',
                direction: '-',
                currentPeriod: null,
                number: '',
                relations: ['status', 'smsstatus', 'activesubscriptions'],
                myDate: {
                    startDate: moment().startOf('month').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                },
                subscribers: null,
                loading: false
            }
        },
        mounted() {
            this.getCustomers()
        },
        methods: {
            isNumber(evt) {
                evt = (evt) ? evt : window.event;
                const charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            getPackages() {
                axios.get(`/keywords/raw?filter[company_id]=${this.company}`)
                    .then(response => {
                        this.packages = response.data
                    });
            },
            sendSms() {
                if (this.customerNumber.length && typeof this.package.name !== 'undefined') {
                    if (confirm('Are you sure?')) {
                        axios.get('/customer-send-manual-sms', {
                            params: {
                                to: this.customerNumber,
                                text: this.package.name
                            }
                        }).then(res => {
                            alert("Sms sent successfully!")
                        }).catch(err => {
                            alert("Error sending log    !")
                        })
                    }
                }
            },
            updateValues() {
                this.currentPeriod = `${this.myDate.startDate} - ${this.myDate.endDate}`
                this.getCustomers()
            }
        },
        created() {
            this.getPackages()
            this.debouncedGetResults = _.debounce(this.getCustomers, 500)
            this.currentPeriod = moment(this.myDate.startDate).format('DD.MM.YYYY') + '-' +  moment(this.myDate.endDate).format('DD.MM.YYYY')
        }
    }
</script>

<style scoped>

</style>