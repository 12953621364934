<template>
    <div class="dashboard-wrapper">

        <div class="label-row">
            <div class="filter-buttons">
                <h4 class="big-title">Operators</h4>
                <span class="current-period">{{ currentPeriod }}</span>
            </div>
        </div>

        <div class="operators-container">
            <operator-card
                :provider="bhTelecom"
                provider-name="BHTelecom"
                :provider-chart="bhTelecomChart"
                logo="/bhtelecom.svg"
                first-color="#FE8300"
                second-color="#FEB55578"
                :loading="loading"
            ></operator-card>

            <operator-card
                :provider="eronet"
                provider-name="HT Eronet"
                :provider-chart="eronetChart"
                logo="/eronet.svg"
                first-color="#419AFF"
                second-color="#419AFF63"
                :loading="loading"
            ></operator-card>

            <operator-card
                :provider="mTel"
                provider-name="M:tel"
                :provider-chart="mTelChart"
                logo="/mtel.svg"
                first-color="#419AFF"
                second-color="#419AFF63"
                :loading="loading"
            ></operator-card>

            <operator-card
                :provider="total"
                provider-name="Total"
                :provider-chart="totalChart"
                first-color="#ff513e"
                second-color="#FFA490"
                :loading="loading"
            ></operator-card>
        </div>

        <Heatmap v-show="showHeatmap" :series="series" :categories="categories" :data-with-details="dataWithDetails" :tooltip-format="tooltipFormat"></Heatmap>

        <messages-table :pagination="pagination" @refreshTable="updateValues" @resendCode="resendCode" @pageChanged="getMessages" @numberHistory="numberHistory" @generateCode="generateCode">
            <div class="refresh-button">
                <button class="btn btn-outline-secondary" @click="showHeatmap = !showHeatmap">{{ showHeatmap ? 'Hide Heatmap ' : 'Show Heatmap'}}</button>
            </div>
            <label>
                <input class="form-control" type="text" v-model="number" @input="debouncedGetResults" placeholder="Search number...">
            </label>

            <date-picker v-model="myDate" @updateDateRange="updateValues"></date-picker>
        </messages-table>
    </div>
</template>

<script>
    import filters from '../../mixins/filters.js'
    import Heatmap from "./Common/Heatmap";

    export default {
        name: "OlxTemplate",
        props: ['company'],
        mixins: [filters],
        components: {Heatmap},
        data: function () {
            return {
                pagination: {},
                sortBy: 'created_at',
                direction: '-', // - is for desc, empty is for asc order

                relations: ['keyword'],
                requestTypeId: 2, // sms that are sent from mobile to our application

                myDate: {
                    startDate: moment().startOf('month').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                },
                currentPeriod: null,
                number: '',

                alerts: [],

                bhTelecom: null,
                eronet: null,
                mTel: null,
                total: null,

                bhTelecomChart: null,
                eronetChart: null,
                mTelChart: null,
                totalChart: null,

                loading: false,
                showHeatmap: false,
                series: [],
                categories: [],
                dataWithDetails: [],
                tooltipFormat: "<div>" +
                    "<p>TOTAL: ${val}</p>" +
                    "<p>OLX5: ${data[seriesIndex]['data'][dataPointIndex]['olx5']}</p>" +
                    "<p>OLX10: ${data[seriesIndex]['data'][dataPointIndex]['olx10']}</p>" +
                    "<p>FAILED: ${data[seriesIndex]['data'][dataPointIndex]['failed']}</p>" +
                    "</div>"
            }
        },
        methods: {
            resendCode(message) {
                axios.get(`/dashboard/${this.company}/resend-code/${parseInt(message.id)}`)
                    .then(res => {
                        alert('Code sent')
                    })
                    .catch(error => {
                    })
            },
            getCardsData() {
                this.loading = true

                this.$http.get(`/dashboard-data/${this.company}?date_from=${this.myDate.startDate}&date_to=${this.myDate.endDate}&include=${this.relations}`)
                    .then(response => {
                        this.bhTelecom = response.data['BHTelecom'] ? response.data['BHTelecom'] : null
                        this.eronet = response.data['Eronet'] ? response.data['Eronet'] : null
                        this.mTel = response.data['Mtel'] ? response.data['Mtel'] : null
                        this.total = response.data['total'] ? response.data['total'] : null
                        this.currentPeriod = response.data['BHTelecom'] ? response.data['BHTelecom'].currentPeriod : null

                        this.loading = false
                    });
            },
            getChartData(provider, initChart) {
                this.$http.get(`/bar-chart-data?company=${this.company}&filter[request_type_id]=${this.requestTypeId}&days=14&${this.dateBetweenFilter}&filter[provider_id]=${provider}${this.companyFilter}&include=${this.relations}`)
                    .then(response => {
                        initChart(response.data)
                    });
            },
            getHeatmapChartData(provider) {
                this.$http.get(`/heatmap-chart-data/${this.company}?filter[request_type_id]=${this.requestTypeId}&days=14&${this.dateBetweenFilter}&filter[provider_id]=${provider}`)
                    .then(response => {
                        // console.log("Heat", response.data)
                        this.dataWithDetails = response.data.data
                        this.categories = response.data.dates

                        this.series = response.data.data.map(function (item) {
                            return {
                                name: item.name,
                                data: item.data.map(function (item2) {
                                    return item2.count
                                })
                            }
                        })
                    });
            },
            updateValues(page) {
                this.getMessages(page)
                this.getCardsData()
                this.getHeatmapChartData('1,2,3')
                this.getChartData('1,2,3', this.initTotalChart)
                this.getChartData(1, this.initBhTelecomChart)
                this.getChartData(2, this.initEronetChart)
                this.getChartData(3, this.initMtelChart)
            },
            initBhTelecomChart(data) {
                this.$set(this, 'bhTelecomChart', {
                    labels: Object.keys(data),
                    datasets: [
                        {
                            backgroundColor: '#FEB555',
                            data: Object.values(data).map(day => day.amount),
                            other: Object.values(data)
                        }
                    ]
                })
            },
            initEronetChart(data) {
                this.$set(this, 'eronetChart', {
                    labels: Object.keys(data),
                    datasets: [
                        {
                            backgroundColor: '#419AFF',
                            data: Object.values(data).map(day => day.amount),
                            other: Object.values(data)
                        }
                    ]
                })
            },
            initMtelChart(data) {
                this.$set(this, 'mTelChart', {
                    labels: Object.keys(data),
                    datasets: [
                        {
                            backgroundColor: '#ff282d',
                            data: Object.values(data).map(day => day.amount),
                            other: Object.values(data)
                        }
                    ]
                })
            },
            initTotalChart(data) {
                this.$set(this, 'totalChart', {
                    labels: Object.keys(data),
                    datasets: [
                        {
                            backgroundColor: '#ff513e',
                            data: Object.values(data).map(day => day.amount),
                            other: Object.values(data)
                        }
                    ]
                })
            },
        },
        mounted() {
            this.getMessages()
        },
        created() {
            this.getCardsData()
            this.getChartData(1, this.initBhTelecomChart)
            this.getChartData(2, this.initEronetChart)
            this.getChartData(3, this.initMtelChart)
            this.getChartData('1,2,3', this.initTotalChart)
            this.getHeatmapChartData('1,2,3')

            this.debouncedGetResults = _.debounce(this.getMessages, 500)
        }
    }
</script>

<style scoped>

</style>
