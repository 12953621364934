<template>
    <div>
        <div class="label-row mt-5">
            <div class="filter-buttons">
                <h4 class="big-title mb-1">Heatmap</h4>
            </div>
        </div>
        <div id="chart">
            <apexchart type="heatmap" height="600" :options="chartOptions" :series="series"></apexchart>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Heatmap",
        props: {
            series: {
                type: Array
            },
            categories: {
                type: Array
            },
            dataWithDetails: {
                type: Array
            },
            tooltipFormat: {
                type: String,
            }
        },
        data() {
            return {
                chartOptions: {
                    plotOptions: {
                        heatmap: {
                            colorScale: {
                                ranges: [
                                    {
                                        from: 0,
                                        to: 0,
                                        color: '#F6F8FB',
                                        // color: '#c73939',
                                        name: 'zero',
                                    },
                                    {
                                        from: 1,
                                        to: 20,
                                        color: '#95CBEE',
                                        name: 'low',
                                    },
                                    {
                                        from: 21,
                                        to: 30,
                                        color: '#FFD73E',
                                        name: 'medium',
                                    },
                                    {
                                        from: 31,
                                        to: 40,
                                        color: '#F05336',
                                        name: 'medium high',
                                    },
                                    {
                                        from: 41,
                                        to: 100,
                                        // color: '#920707',
                                        color: '#c73939',
                                        name: 'high',
                                    }
                                ]
                            }
                        }
                    },
                    chart: {
                        height: 550,
                        type: 'heatmap',
                    },
                    dataLabels: {
                        enabled: false
                    },
                    colors: ["#008FFB"],
                    title: {
                        text: ''
                    },
                    xaxis: {
                        type: 'category',
                        categories: []
                    },
                },
            }
        },
        computed: {
            getExactDataSet() {
                return this.dataWithDetails
            },
            getTooltipFormat() {
                return this.tooltipFormat
            }
        },
        watch: {
            series() {
                this.updateChart()
            }
        },
        methods: {
            updateChart() {
                // Data is used iside toTemplate function
                let data = this.getExactDataSet;
                let tooltipFormat = this.getTooltipFormat;
                // toTemplate function creates template literal from given string
                String.prototype.toTemplate = function(val, seriesIndex, dataPointIndex){
                    return eval('`'+this+'`');
                }
                // Merge updated chart options
                this.chartOptions = {...this.chartOptions, ...{
                        tooltip: {
                            enabled: true,
                            y: {
                                formatter: function(val, {seriesIndex, dataPointIndex}) {
                                    // console.log("Points", seriesIndex, dataPointIndex)
                                    return tooltipFormat ? tooltipFormat.toTemplate(val, seriesIndex, dataPointIndex) : val;
                                },
                                title: {
                                    formatter: function (seriesName) {
                                        // console.log("name", seriesName)
                                        return seriesName
                                    }
                                }
                            }
                        },
                        xaxis: {
                            categories: this.categories
                        }
                    }};
            }
        }
    }
</script>
