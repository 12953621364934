<template>
    <div>
        <a :id="`${name}Dropdown`" class="custom-dropdown" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span>{{ value ? value.name : capitalize(name) }}</span> <i class="fa fa-angle-down"></i>
        </a>

        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="statusDropdown">
            <a class="dropdown-item" @click="selectOption(option)" v-for="option in options">
                {{ option ? option.name : 'All' }}
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FilterDropdown",
        props: ['name', 'options', 'value'],
        methods: {
          selectOption(option) {
              this.$emit('input', option)
              this.$emit('changedSelection')
          }
        }
    }
</script>

<style scoped>
    .custom-dropdown {
        width: 130px;
        height: 32px;
        padding-left: 12px;
        padding-top: 6px;
        background: #FFFFFF 0 0 no-repeat padding-box;
        box-shadow: 0 2px 3px #0000000D;
        border: 1px solid #D7DAE2;
        border-radius: 4px;
        opacity: 1;
        color: #4D4F5C;
        font: Normal 13px/20px Source Sans Pro;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
    }

    .dropdown-item {
        cursor: pointer;
    }

    .fa-angle-down {
        color: #A4AFB7;
        line-height: 1.7;
        margin-right: 8px;
    }
</style>