<template>
    <div class="dashboard-wrapper">

        <div class="label-row">
            <div class="filter-buttons">
                <h4 class="big-title">Customers</h4>
                <span class="current-period">{{ currentPeriod }}</span>
            </div>
            <div class="filter-buttons" v-if="typeof myDate.startDate !== 'undefined'">
                <a href="" @click.prevent="printWindow()">Print</a>
                <a :href="`/bihamk-get-excel-report/${company}/${myDate.startDate}/${myDate.endDate}`" download>XLS</a>
                <a :href="`/bihamk-get-pdf-report/${company}/${myDate.startDate}/${myDate.endDate}`" download>PDF</a>
            </div>
        </div>

        <div class="operators-container">
            <div class="d-flex justify-content-between flex-row flex-grow-1">
                <customer-card :customers="customers"
                               style="flex-grow: 1; margin-right: 30px;"
                               :customers-chart="newCustomersChart"
                               first-color="#ff513e"
                               second-color="#FFA490"
                               :loading="loading"
                               card-title="New customers">
                </customer-card>

                <customer-card :customers="payments"
                               style="flex-grow: 1;"
                               :customers-chart="paymentsChart"
                               first-color="#FE8300"
                               second-color="#FEB55578"
                               :loading="loading"
                               card-title="Recurring payments">
                </customer-card>

                <pie-chart-card
                    :provider="{id: 1}"
                    :provider-chart="doughnutChart"
                    :loading="loading"
                    title="Packages"
                ></pie-chart-card>

            </div>
        </div>

        <payments-table :pagination="pagination" @numberHistory="numberSubscriptionHistory" @refreshTable="updateValues" @pageChanged="getPayments">
            <label>
                <input class="form-control" type="text" v-model="number" @input="debouncedGetResults" placeholder="Search number...">
            </label>

            <date-picker v-model="myDate" @updateDateRange="updateValues"></date-picker>
        </payments-table>
    </div>
</template>

<script>
    import filters from "../../mixins/filters";

    export default {
        name: "BihamkTemplate",
        props: ['company'],
        mixins: [filters],
        data() {
            return {
                pagination: {},
                sortBy: 'created_at',
                direction: '-',
                number: '',
                // myDate: {
                //     startDate: moment().format('YYYY-MM-DD'),
                //     endDate: moment().format('YYYY-MM-DD')
                // },
                myDate: {
                    startDate: moment().startOf('month').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                },
                currentPeriod: null,
                customers: null,
                payments: null,
                loading: false,
                newCustomersChart: null,
                paymentsChart: null,
                requestTypeId: 2,
                doughnutChart: null,
            }
        },
        mounted() {
            this.getPayments()
        },
        methods: {
            printWindow() {
                window.print()
            },
            updateValues(page) {
                this.getPayments(page)
                this.getCardsData()
                this.initCustomersChart()
                this.initPaymentsChart()
                this.getDoughnutChartData()
            },
            getCardsData() {
                this.loading = true

                this.$http.get(`/dashboard-data-bihamk/${this.company}?date_from=${this.myDate.startDate}&date_to=${this.myDate.endDate}&include=${this.relations}`)
                    .then(response => {
                        this.customers = response.data['customers']
                        this.payments = response.data['payments']
                        // this.customers = response.data['total'] ? response.data['total'] : null
                        this.currentPeriod = response.data['customers'].currentPeriod !== undefined ? response.data['customers'].currentPeriod : null

                        this.loading = false
                    });
            },
            getDoughnutChartData() {
                this.$http.get(`/bihamk-doughnut-chart-data/${this.company}?${this.dateBetweenFilter}&${this.companyFilter}`)
                    .then(response => {
                        this.doughnutChart = {
                            labels: Object.keys(response.data.dataset),
                            datasets: [
                                {
                                    backgroundColor: [
                                        '#60CAF9',
                                        '#419AFF',
                                        '#FFB6B6',
                                        '#cb5757',
                                        '#9be2a2',
                                        '#cd9816',
                                    ],
                                    data: Object.values(response.data.dataset),
                                    percentages: response.data.percentages
                                }
                            ]
                        }
                    });
            },
            initCustomersChart() {
                this.$http.get(`/bihamk-bar-chart-customers-data/${this.company}?&days=14&${this.dateBetweenFilter}`)
                    .then(response => {
                        let data = response.data
                        this.$set(this, 'newCustomersChart', {
                            labels: Object.keys(data),
                            datasets: [
                                {
                                    backgroundColor: '#FEB555',
                                    data: Object.values(data).map(day => day.count),
                                    other: Object.values(data)

                                }
                            ]
                        })
                    });
            },
            initPaymentsChart() {
                this.$http.get(`/bihamk-bar-chart-payments-data/${this.company}?&days=14&${this.dateBetweenFilter}`)
                    .then(response => {
                        let data = response.data
                        this.$set(this, 'paymentsChart', {
                            labels: Object.keys(data),
                            datasets: [
                                {
                                    backgroundColor: '#FEB555',
                                    data: Object.values(data).map(day => day.count),
                                    other: Object.values(data)

                                }
                            ]
                        })
                    });
            }
        },
        created() {
            this.getCardsData()
            this.initCustomersChart()
            this.initPaymentsChart()
            this.getDoughnutChartData()

            this.debouncedGetResults = _.debounce(this.getPayments, 500)

        }
    }
</script>

<style scoped>
    .pie-chart-card {
        flex-grow: 2;
        margin-right: 0 !important;
    }

    .filter-buttons a {
        padding: 0 10px;
    }
</style>
