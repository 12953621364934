<template>
    <div class="dashboard-wrapper">
        <div class="label-row">
            <div class="filter-buttons">
                <h4 class="big-title">Subscribers</h4>
                <span class="current-period">{{ currentPeriod }}</span>
            </div>
        </div>

        <div style="display: grid; grid-gap: 30px; grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));">
            <div style="background: #fff; box-shadow: 0 2px 10px #00000033; border-radius: 5px; padding: 5px" class="grid-item" v-for="(statBox, index) in statBoxes"  >
                <h5>{{index}}</h5>
              <strong>{{statBox.length}}</strong>
            </div>
        </div>

        <subscriptions-table :pagination="pagination" @refreshTable="updateValues" @numberHistory="numberSubscriptionHistory" @pageChanged="getActiveSubscribers">
            <label>
                <input class="form-control" type="text" v-model="number" @input="debouncedGetResults" placeholder="Search number...">
            </label>

            <date-picker v-model="myDate" @updateDateRange="updateValues"></date-picker>
        </subscriptions-table>

    </div>
</template>

<script>
    import filters from "../../mixins/filters";
    import StatsCard from "./Common/StatsCard";

    export default {
      components: {StatsCard},
        name: "ActiveSubscribers",
        props: {
            company: {
                type: Number
            }
        },
        mixins: [filters],
        data() {
            return {
                pagination: {},
                sortBy: 'created_at',
                direction: '-',
                currentPeriod: null,
                number: '',
                myDate: {
                    startDate: moment().startOf('month').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                },
                subscribers: null,
                loading: false,
                statBoxes: []
            }
        },
        mounted() {
            this.getActiveSubscribers()
        },
        methods: {
            updateValues() {
                this.currentPeriod = `${this.myDate.startDate} - ${this.myDate.endDate}`
                this.getActiveSubscribers()
            }
        },
        created() {
          this.$http.get(`/subscribers/stat-boxes` +
              `?sort=${this.direction + this.sortBy}` +
              `&filter[company_id]=${this.company}` +
              `&filter[search_term]=${this.number}` +
              // `${this.fromFilter}` +
              `${this.dateBetweenFilter}` +
              `${this.statusIdFilter}`)
              .then(response => {
                console.log("asd", response)
                this.statBoxes = response.data
              });
            this.debouncedGetResults = _.debounce(this.getActiveSubscribers, 500)
            this.currentPeriod = moment(this.myDate.startDate).format('DD.MM.YYYY') + '-' +  moment(this.myDate.endDate).format('DD.MM.YYYY')
        }
    }
</script>

<style scoped>

</style>
