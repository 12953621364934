export default {
    data: function () {
        return {
            /*
            |--------------------------------------------------------------------------
            | chartOptions
            |--------------------------------------------------------------------------
            |
            | Chart options
            |
            */

            barChartOptions: {
                onHover: function (evt, arr) {
                    let tooltip = document.getElementById('tooltip-span')
                    if (arr !== undefined && arr.length !== 0) {
                        let index = arr[0]._index
                        let label = arr[0]._chart.tooltip._data.labels[index]
                        let other = arr[0]._chart.tooltip._data.datasets[0].other[index]

                        tooltip.innerHTML = `<strong>${label}</strong>` +
                            `<br>Count: ${other.count}` +
                            `<br>Amount: ${other.amount} KM` +
                            `<br>Users: ${other.uniqueUsers}`;

                        if (other.keywords) {
                            tooltip.innerHTML += '<br><br>Count by keywords:'
                        }
                        Object.keys(other.keywords).forEach(key => {
                            tooltip.innerHTML += `<br>${key}: ${other.keywords[key]}`
                        })

                        tooltip.style.display = 'block';
                        tooltip.style.top = (evt.clientY + 20) + 'px';
                        tooltip.style.left = (evt.clientX + 20) + 'px';
                        tooltip.style.position = 'fixed';
                        tooltip.style.padding = '10px';
                        tooltip.style.background = '#333';
                        tooltip.style.color = '#fff';
                        tooltip.style.boxShadow = '2px 2px 5px #aaa';
                        tooltip.style.transition = 'opacity 250ms ease-out';
                        tooltip.style.fontSize = '12px';
                        tooltip.style.borderRadius = '5px';
                    } else {
                        tooltip.style.display = 'none';
                    }
                },
                scales: {
                    yAxes: [{
                        display: false,
                        ticks: {
                            beginAtZero: true
                        },
                        gridLines: {
                            display: false
                        }
                    }],
                    xAxes: [{
                        display: false,
                        ticks: {
                            beginAtZero: true
                        },
                        gridLines: {
                            display: false
                        }
                    }]
                },
                legend: {
                    display: false
                },
                tooltips: {
                    // false since we have custom tooltip
                    enabled: false,
                },
                responsive: true,
                maintainAspectRatio: false
            },

            customerBarChartOptions: {
                onHover: function (evt, arr) {
                    let tooltip = document.getElementById('tooltip-span')
                    if (arr !== undefined && arr.length !== 0) {
                        let index = arr[0]._index
                        let label = arr[0]._chart.tooltip._data.labels[index]
                        let other = arr[0]._chart.tooltip._data.datasets[0].other[index]

                        tooltip.innerHTML = `<strong>${label}</strong>` +
                            `<br>Count: ${other.count}`;

                        tooltip.style.display = 'block';
                        tooltip.style.top = (evt.clientY + 20) + 'px';
                        tooltip.style.left = (evt.clientX + 20) + 'px';
                        tooltip.style.position = 'fixed';
                        tooltip.style.padding = '10px';
                        tooltip.style.background = '#333';
                        tooltip.style.color = '#fff';
                        tooltip.style.boxShadow = '2px 2px 5px #aaa';
                        tooltip.style.transition = 'opacity 250ms ease-out';
                        tooltip.style.fontSize = '12px';
                        tooltip.style.borderRadius = '5px';
                    } else {
                        tooltip.style.display = 'none';
                    }
                },
                scales: {
                    yAxes: [{
                        display: false,
                        ticks: {
                            beginAtZero: true
                        },
                        gridLines: {
                            display: false
                        }
                    }],
                    xAxes: [{
                        display: false,
                        ticks: {
                            beginAtZero: true
                        },
                        gridLines: {
                            display: false
                        }
                    }]
                },
                legend: {
                    display: false
                },
                tooltips: {
                    // false since we have custom tooltip
                    enabled: false,
                },
                responsive: true,
                maintainAspectRatio: false
            },

            stackedChartOptions: {
                type: 'groupableBar',
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                max: 160,
                            },
                            stacked: true,
                        }]
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            },

            doughnutChartOptions: {
                onHover: function (evt, arr) {
                    let tooltip = document.getElementById('tooltip-span')
                    if (arr !== undefined && arr.length !== 0) {
                        let index = arr[0]._index
                        let label = arr[0]._chart.tooltip._data.labels[index]
                        // let value = arr[0]._chart.tooltip._data.datasets[0].data[index]

                        // Value is now included with percentage
                        let percentage = arr[0]._chart.tooltip._data.datasets[0].percentages[label]

                        tooltip.innerText = label + ': ' + percentage;

                        tooltip.style.display = 'block';
                        tooltip.style.top = (evt.clientY + 20) + 'px';
                        tooltip.style.left = (evt.clientX + 20) + 'px';
                        tooltip.style.position = 'fixed';
                        tooltip.style.padding = '10px';
                        tooltip.style.background = '#333';
                        tooltip.style.color = '#fff';
                        tooltip.style.boxShadow = '2px 2px 5px #aaa';
                        tooltip.style.transition = 'opacity 250ms ease-out';
                        tooltip.style.fontSize = '12px';
                        tooltip.style.borderRadius = '5px';
                    } else {
                        tooltip.style.display = 'none';
                    }
                },
                cutoutPercentage: 60,
                legend: {
                    display: true,
                    position: 'right',
                    labels: {
                        generateLabels: function (chart) {
                            var data = chart.data;
                            if (data.labels.length && data.datasets.length) {
                                return data.labels.map(function(label, i) {
                                    var meta = chart.getDatasetMeta(0);
                                    var ds = data.datasets[0];
                                    var arc = meta.data[i];
                                    var custom = arc && arc.custom || {};
                                    var getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
                                    var arcOpts = chart.options.elements.arc;
                                    var fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                                    var stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                                    var bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

                                    // We get the value of the current label
                                    // var value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];

                                    // Value is now included with percentage
                                    let percentage = chart.config.data.datasets[0].percentages[label]

                                    return {
                                        // Instead of `text: label,`
                                        // We add the value to the string
                                        // text: label + " - " + value,
                                        text: label + " - " + percentage,
                                        fillStyle: fill,
                                        strokeStyle: stroke,
                                        lineWidth: bw,
                                        hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                                        index: i
                                    };
                                });
                            } else {
                                return [];
                            }
                        }
                    }
                },
                tooltips: {
                    // false since we have custom tooltip
                    enabled: false,
                },
                responsive: true,
                maintainAspectRatio: false,
            }
        }
    },
}
