<template>
    <div class="dashboard-wrapper">
        <div class="label-row">
            <div class="filter-buttons">
                <h4 class="big-title">{{ number }}</h4>
                <div class="refresh-button" v-if="isBanned">
                    <button class="btn btn-outline-secondary" @click="unbanNumber">Unban</button>
                </div>
<!--                <span class="current-period">{{ currentPeriod }}</span>-->
            </div>
            <div class="filter-buttons">
                <a href="" @click="printWindow()">Print</a>
<!--                <a :href="`/export-excel/${company}/${provider.id}/${myDate.startDate}/${myDate.endDate}`" download>XLS</a>-->
<!--                <a :href="`/export-pdf/${company}/${provider.id}/${myDate.startDate}/${myDate.endDate}`" download>PDF</a>-->
            </div>
        </div>

        <messages-table :pagination="pagination" @refreshTable="getMessages" @pageChanged="getMessages" @generateCode="generateCode">
            <custom-dropdown
                    name="status"
                    :options="statusFilterOptions"
                    v-model="status"
                    @changedSelection="getMessages"
            ></custom-dropdown>

            <custom-dropdown
                    name="keyword"
                    :options="keywords"
                    v-model="keyword"
                    @changedSelection="getMessages"
            ></custom-dropdown>

            <date-picker v-model="myDate" @updateDateRange="updateValues"></date-picker>
        </messages-table>
    </div>
</template>

<script>
    import filters from '../../mixins/filters.js'
    export default {
        name: "NumberDetails",
        props: ['number', 'company', 'banned'],
        mixins: [filters],
        data: function () {
            return {
                details: null,
                pagination: {},
                sortBy: 'created_at',
                direction: '-', // - is for desc, empty is for asc order

                requestTypeId: 2, // sms that are sent from mobile to our application

                relations: ['keyword'],

                providers: [],
                keywords: [],

                // Filters
                provider: null,
                keyword: null,
                currentPeriod: '',
                status: '',
                statusFilterOptions: [
                    null, // option for All
                    { value: 'OK', name: 'OK'},
                    { value: 'NOT OK', name: 'NOT OK'}
                ],

                // date range picker
                myDate: {
                    startDate: moment().startOf('month').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                },

                isBanned: false,
            }
        },
        methods: {
            updateValues() {
                this.currentPeriod = `${this.myDate.startDate} - ${this.myDate.endDate}`
                this.getMessages()
            },
            printWindow() {
                window.print()
            },
            unbanNumber() {
                this.$http.get(`/unban/${this.number}`)
                    .then(response => {
                        this.$snotify.success(response.data.status)
                        this.isBanned = false
                    });
            }
        },
        mounted() {
            this.getMessages()
        },
        created() {
            this.getProviders()
            this.getKeywords()

            this.isBanned = this.banned

            // this.details = this.data[Object.keys(this.data)[0]]
            // this.provider = this.details.provider
            // this.currentPeriod = this.details.currentMonth
        }
    }
</script>

<style scoped>

</style>