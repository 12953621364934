<template>
    <div class="dashboard-wrapper">
        <div class="label-row">
            <div class="filter-buttons">
                <h4 class="big-title">Customers needing help</h4>
<!--                <span class="current-period">{{ currentPeriod }}</span>-->
            </div>
        </div>

        <customer-help-table :pagination="pagination" @refreshTable="updateValues" @pageChanged="getCustomersNeedingHelp">

        </customer-help-table>

    </div>
</template>

<script>
    export default {
        name: "CustomerHelp",
        props: {
            company: {
                type: [Number, String]
            }
        },
        mounted() {
            this.getCustomersNeedingHelp()
        },
        methods: {
            updateValues(page) {
                this.getCustomersNeedingHelp(page)
            }
        },
        data () {
            return {
                pagination: {},
                loading: false,
                failedAttempts: 1
            }
        },
        created() {
            this.debouncedGetResults = _.debounce(this.getCustomersNeedingHelp, 500)
        }
    }
</script>

<style scoped>

</style>
