<template>
    <div class="card shadow-sm">
        <div class="card-header">
            <h5 class="d-inline"><strong>{{ capitalize(content.replace('_', ' ')) }}</strong></h5>
            <a :href="`/${content}`"><button class="link-style btn btn-sm btn-danger float-right"><i class="fas fa-arrow-circle-left"></i> Go Back</button></a>
        </div>

        <div class="card-body">
            <div>
                <vue-snotify></vue-snotify>
                <div class="panel-body">
                    <form @submit.prevent="submitForm">
                        <vue-form-generator :schema="schemas[content]" :model="model" :options="formOptions">
                        </vue-form-generator>

                        <button type="submit" class="btn btn-sm btn-success float-right mt-4">Save</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import form from '../mixins/form'
    export default {
        props: ['content', 'model', 'includes'],
        mixins: [form],
        data: () => ({
            formOptions: {
                validateAfterLoad: true,
                validateAfterChanged: true,
                validateAsync: true,
            },
            preloaded: []
        }),
        methods: {
            // methods
        },
        created() {
            this.mapRelations()

            this.includes.forEach(element => {
                if (element) {
                    let include = this.$pluralize(element, 2)
                    this.$set(this.preloaded, include, [])
                    this.$http.get(`/${include}/raw`).then((response) => {
                        response.data.data.forEach(item => {
                            this.preloaded[include].push(item)
                        })
                    }).catch((error) => {
                        this.$snotify.error(this.errorMessage(error))
                    })
                }
            })
        }
    }
</script>

<style scoped>

</style>