<template>
    <div class="dashboard-wrapper">
        <img class="misija-logo" src="/logo.png" alt="Misija">
        <span id="tooltip-span"></span>

        <div class="label-row" style="margin-top: 50px;">
            <div class="company">
                <h4 class="big-title">{{ company.name }}</h4>
                <h5>{{ company.address }}</h5>
                <h5>71000 {{ company.city }}</h5>
            </div>

            <span class="current-period">{{ details.currentPeriod }}</span>
        </div>
<!--        <div class="label-row">-->
<!--            <div class="filter-buttons">-->
<!--                <a href="" @click="printWindow()">Print</a>-->
<!--            </div>-->
<!--        </div>-->

        <div class="wrapper">
            <h4>Operater: {{ provider.name }}</h4>

            <table>
                <tr>
                    <th>Naziv</th>
                    <th>Količina</th>
                    <th>Cijena</th>
                    <th>Iznos</th>
                </tr>
                <tr v-if="0">
                    <th colspan="6">
                        <span class="loader-icon"></span>
                    </th>
                </tr>
                <tr v-if="1"  v-for="(keyword, index) in details.keywords">
                    <td>SMS {{ index }}</td>
                    <td>{{ keyword.count }}</td>
                    <td>{{ keyword.price }}</td>
                    <td>{{ keyword.amount }} KM</td>
                </tr>
                <tr>
                    <td><strong>Ukupno</strong></td>
                    <td></td>
                    <td></td>
                    <td><strong>{{ details.amount }} KM</strong></td>
                </tr>
            </table>

            <div class="charts-container">
                <div class="chart" v-if="barChart">
                    <bar-chart :chart-data="barChart" :chart-options="barChartOptions" first-color="#419AFF" second-color="#419AFF63"></bar-chart>
                </div>

                <pie-chart-card
                        :provider="details"
                        :provider-chart="doughnutChart"
                ></pie-chart-card>
            </div>

            <table>
                <tr>
                    <th>Naziv</th>
                    <th>Količina</th>
                    <th>Cijena</th>
                    <th>Iznos</th>
                </tr>
                <tr v-if="0">
                    <th colspan="6">
                        <span class="loader-icon"></span>
                    </th>
                </tr>
                <tr>
                    <td>Povratni SMS</td>
                    <td>{{ details.fromApp }}</td>
                    <td>{{ details.response_price }} KM</td>
                    <td>{{ Number(details.fromApp * details.response_price).toFixed(2) }} KM</td>
                </tr>
                <tr>
                    <td><strong>Ukupno</strong></td>
                    <td></td>
                    <td></td>
                    <td><strong>{{ Number(details.fromApp * details.response_price).toFixed(2) }} KM</strong></td>
                </tr>
            </table>

            <h5 style="margin-top: 100px; float: right;">Sarajevo, {{ details.date }}</h5>
        </div>
    </div>
</template>

<script>
    import filters from '../../mixins/filters.js'
    import chartOptions from '../../mixins/chart.js'
    export default {
        name: "ReportDetails",
        props: ['data', 'company'],
        mixins: [chartOptions, filters],
        data: function () {
            return {
                details: null,

                // charts
                barChart: null,
                doughnutChart: null,

                requestTypeId: 2,

                myDate: {
                    startDate: '',
                    endDate: ''
                },

                barChartOptions: {
                    onHover: function (evt, arr) {
                        let tooltip = document.getElementById('tooltip-span')
                        if (arr !== undefined && arr.length !== 0) {
                            let index = arr[0]._index
                            let label = arr[0]._chart.tooltip._data.labels[index]
                            let other = arr[0]._chart.tooltip._data.datasets[0].other[index]

                            tooltip.innerHTML = `<strong>${label}</strong>` +
                                `<br>Count: ${other.count}` +
                                `<br>Amount: ${other.amount} KM` +
                                `<br>Users: ${other.uniqueUsers}`;

                            if (other.keywords) {
                                tooltip.innerHTML += '<br><br>Count by keywords:'
                            }
                            Object.keys(other.keywords).forEach(key => {
                                tooltip.innerHTML += `<br>${key}: ${other.keywords[key]}`
                            })

                            tooltip.style.display = 'block';
                            tooltip.style.top = (evt.clientY + 20) + 'px';
                            tooltip.style.left = (evt.clientX + 20) + 'px';
                            tooltip.style.position = 'fixed';
                            tooltip.style.padding = '10px';
                            tooltip.style.background = '#333';
                            tooltip.style.color = '#fff';
                            tooltip.style.boxShadow = '2px 2px 5px #aaa';
                            tooltip.style.transition = 'opacity 250ms ease-out';
                            tooltip.style.fontSize = '12px';
                            tooltip.style.borderRadius = '5px';
                        } else {
                            tooltip.style.display = 'none';
                        }
                    },
                    scales: {
                        yAxes: [{
                            display: true,
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                display: false
                            }
                        }],
                        xAxes: [{
                            display: true,
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                display: false
                            }
                        }]
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        // false since we have custom tooltip
                        enabled: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false
                },
            }
        },
        methods: {
            getChartData(provider, initChart) {
                // this.$http.get(`/bar-chart-data?company=${this.company.id}&filter[request_type_id]=${this.requestTypeId}${this.dateBetweenFilter}&filter[provider_id]=${provider}${this.companyFilter}`)
                this.$http.get(`/bar-chart-data?company=${this.company.id}&filter[request_type_id]=${this.requestTypeId}${this.dateBetweenCustomFilter}&filter[provider_id]=${provider}${this.companyFilter}`)
                    .then(response => {
                        initChart(response.data)
                    });
            },
            getDoughnutChartData(provider, initDoughnut) {
                // this.$http.get(`/doughnut-chart-data?company=${this.company.id}${this.dateBetweenFilter}&filter[provider_id]=${provider}${this.companyFilter}`)
                this.$http.get(`/doughnut-chart-data?company=${this.company.id}${this.dateBetweenCustomFilter}&filter[provider_id]=${provider}${this.companyFilter}`)
                    .then(response => {
                        initDoughnut(response.data)
                    });
            },
            initBarChart(data) {
                this.barChart = {
                    labels: Object.keys(data),
                    datasets: [
                        {
                            backgroundColor: '#54f3f8',
                            data: Object.values(data).map(day => day.amount),
                            other: Object.values(data)
                        }
                    ]
                }
            },
            initDoughnutChart({dataset, percentages}) {
                this.doughnutChart = {
                    labels: Object.keys(dataset),
                    datasets: [
                        {
                            backgroundColor: [
                                '#60CAF9',
                                '#419AFF',
                                '#FFB6B6'
                            ],
                            data: Object.values(dataset),
                            percentages: percentages
                        }
                    ]
                }
            },
            printWindow() {
                window.print()
            }
        },
        created() {
            this.details = this.data[Object.keys(this.data)[0]]
            this.provider = this.details.provider
            this.myDate.startDate = moment(this.details.startDate).format('YYYY-MM-DD')
            this.myDate.endDate = moment(this.details.endDate).format('YYYY-MM-DD')
            console.log("END DATE: ", this.details.endDate, moment(this.details.endDate).format('YYYY-MM-DD'))
            this.getChartData(this.provider.id, this.initBarChart)
            this.getDoughnutChartData(this.provider.id, this.initDoughnutChart)
        }
    }
</script>

<style scoped>
    .misija-logo {
        width: 200px;
    }

    .big-title {
        margin-bottom: 10px;
    }

    .wrapper {
        /*display: flex;*/
        /*flex-direction: column;*/
        margin-top: 30px;
    }

    .wrapper h4 {
        margin: 40px 0;
    }

    table {
        width: 90%;
    }

    th {
        font-family: Roboto, Bold, sans-serif;
        background: #F5F6FA;
        padding: 15px 20px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        text-align: left;
        letter-spacing: 0.41px;
        color: #87878A;
        opacity: 1;
    }

    td {
        padding: 20px 20px;
        font: Normal 15px/20px Source Sans Pro;
        color: #4D4F5C;
    }

    tr:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .chart {
        width: 60%;
        margin-left: 1vw;
    }

    .chart >>> #bar-chart {
        height: 200px!important;
    }

    .charts-container {
        margin: 50px 0;
        display: flex;
        /*justify-content: center;*/
        flex-direction: column;
        /*align-items: center;*/
    }

    .filter-buttons a {
        padding: 0 10px;
    }

    .pie-chart-card {
        /*margin-left: 30px;*/
        /*margin-right: 30px;*/
        margin-top: 30px;
        background: #fff;
        display: flex;
        flex-direction: column;
        /*justify-content: space-between;*/
        width: 40%;
        height: 150px;
        margin-left: 10vw;
        /*padding: 16px;*/
        /*box-shadow: 0 2px 10px #00000033;*/
        /*border-radius: 10px;*/
    }

    .pie-chart-card.empty {
        justify-content: center;
        align-items: center;
        font-size: 17px;
    }

    .pie-chart-card >>> #doughnut-chart {
        height: 120px!important;
    }
</style>
