<template>
    <div class="dashboard-wrapper">
        <div class="label-row">
            <div class="filter-buttons">
                <h4 class="big-title">{{ provider.name }}</h4>
                <span class="current-period">{{ currentPeriod }}</span>
            </div>
            <div class="filter-buttons">
                <a href="" @click="printWindow()">Print</a>
                <a :href="`/export-excel/${company}/${provider.id}/${myDate.startDate}/${myDate.endDate}`" download>XLS</a>
                <a :href="`/export-pdf/${company}/${provider.id}/${myDate.startDate}/${myDate.endDate}`" download>PDF</a>
            </div>
        </div>

        <div class="operators-container">
            <operator-card
                    :provider="details"
                    :provider-name="details.provider.name"
                    :provider-chart="barChart"
                    first-color="#419AFF"
                    second-color="#419AFF63"
                    :loading="loading"
            ></operator-card>

            <pie-chart-card
                    :provider="details"
                    :provider-chart="doughnutChart"
                    :loading="loading"
            ></pie-chart-card>

            <stats-card
                    :stats="stats"
                    :loading="loading"
            ></stats-card>
        </div>

        <Heatmap v-show="showHeatmap" :series="series" :categories="categories" :data-with-details="dataWithDetails" :tooltip-format="tooltipFormat"></Heatmap>

        <messages-table :pagination="pagination" @refreshTable="updateValues" @pageChanged="getMessages" @numberHistory="numberHistory" @generateCode="generateCode">
            <div class="refresh-button">
                <button class="btn btn-outline-secondary" @click="showHeatmap = !showHeatmap">{{ showHeatmap ? 'Hide Heatmap ' : 'Show Heatmap'}}</button>
            </div>
            <label>
                <input class="form-control" type="text" v-model="number" @input="debouncedGetResults" placeholder="Search number...">
            </label>
            <custom-dropdown
                    name="status"
                    :options="statusFilterOptions"
                    v-model="status"
                    @changedSelection="getMessages"
            ></custom-dropdown>

            <custom-dropdown
                    name="keyword"
                    :options="keywords"
                    v-model="keyword"
                    @changedSelection="getMessages"
            ></custom-dropdown>

            <date-picker v-model="myDate" @updateDateRange="updateValues"></date-picker>
        </messages-table>
    </div>
</template>

<script>
    import filters from '../../mixins/filters.js'
    import Heatmap from "./Common/Heatmap";
    export default {
        name: "ProviderDetails",
        props: ['providerProp', 'company'],
        mixins: [filters],
        components: {Heatmap},
        data: function () {
            return {
                details: {
                    stats: null,
                    provider: {
                        name: ''
                    },
                },
                pagination: {},
                sortBy: 'created_at',
                direction: '-', // - is for desc, empty is for asc order

                requestTypeId: 2, // sms that are sent from mobile to our application

                relations: ['keyword'],

                providers: [],
                keywords: [],

                // Filters
                provider: this.providerProp,
                keyword: null,
                currentPeriod: '',
                status: '',
                statusFilterOptions: [
                    null, // option for All
                    { value: 'OK', name: 'OK'},
                    { value: 'NOT OK', name: 'NOT OK'}
                ],
                number: '',

                stats: [],

                // date range picker
                myDate: {
                    startDate: moment().startOf('month').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                },

                // charts
                barChart: null,
                doughnutChart: null,

                loading: false,
                showHeatmap: false,
                series: [],
                categories: [],
                dataWithDetails: [],
                tooltipFormat: "<div>" +
                    "<p>SMS: ${val}</p>" +
                    "<p>OLX5: ${data[seriesIndex]['data'][dataPointIndex]['olx5']}</p>" +
                    "<p>OLX10: ${data[seriesIndex]['data'][dataPointIndex]['olx10']}</p>" +
                    "</div>"
            }
        },
        methods: {
            getProviderData() {
                this.loading = true

                this.$http.get(`/data-by-provider/${this.company}?date_from=${this.myDate.startDate}&date_to=${this.myDate.endDate}&provider_id=${this.provider.id}&include=${this.relations}`)
                        .then(response => {
                            this.details = response.data[Object.keys(response.data)[0]]
                            this.currentPeriod = this.details.currentPeriod
                            // this.initDoughnutChart()
                            this.initStatsCard()

                            this.loading = false
                        });
            },
            getChartData(provider, initChart) {
                this.$http.get(`/bar-chart-data?company=${this.company}&days=14&filter[request_type_id]=${this.requestTypeId}${this.dateBetweenFilter}&filter[provider_id]=${provider}${this.companyFilter}&include=${this.relations}`)
                    .then(response => {
                        initChart(response.data)
                    });
            },
            getDoughnutChartData(provider, initDoughnut) {
                this.$http.get(`/doughnut-chart-data?company=${this.company}${this.dateBetweenFilter}&filter[provider_id]=${provider}${this.companyFilter}&failed=1&include=${this.relations}`)
                    .then(response => {
                        initDoughnut(response.data)
                    });
            },
            updateValues(page) {
                this.getMessages(page)
                this.getHeatmapChartData(this.providerProp.id)
                this.getChartData(this.providerProp.id, this.initBarChart)
                this.getDoughnutChartData(this.providerProp.id, this.initDoughnutChart)
                this.getProviderData()
            },
            initBarChart(data) {
                this.barChart = {
                    labels: Object.keys(data),
                    datasets: [
                        {
                            backgroundColor: '#54f3f8',
                            data: Object.values(data).map(day => day.amount),
                            other: Object.values(data)
                        }
                    ]
                }
            },
            initDoughnutChart({dataset, percentages}) {
                this.doughnutChart = {
                    labels: Object.keys(dataset),
                    datasets: [
                        {
                            backgroundColor: [
                                '#60CAF9',
                                '#419AFF',
                                '#FFB6B6'
                            ],
                            data: Object.values(dataset),
                            percentages: percentages
                        }
                    ]
                }
            },
            initStatsCard() {
                if (this.details.stats) {
                    this.stats = [
                        {
                            title: 'Daily',
                            value: this.details.stats.dailyAmount,
                            description: 'KM'
                        },
                        {
                            title: 'Users',
                            value: this.details.stats.users,
                            description: 'unique'
                        },
                        {
                            title: 'Daily',
                            value: this.details.stats.dailySms,
                            description: 'sms count'
                        }
                    ]
                } else {
                    this.stats = null
                }
            },
            printWindow() {
                window.print()
            },
            getHeatmapChartData(provider) {
                this.$http.get(`/heatmap-chart-data/${this.company}?filter[request_type_id]=${this.requestTypeId}&days=14&${this.dateBetweenFilter}&filter[provider_id]=${provider}`)
                    .then(response => {
                        // console.log("Heat", response.data)
                        this.dataWithDetails = response.data.data
                        this.categories = response.data.dates

                        this.series = response.data.data.map(function (item) {
                            return {
                                name: item.name,
                                data: item.data.map(function (item2) {
                                    return item2.count
                                })
                            }
                        })
                    });
            },
        },
        mounted() {
            this.getMessages()
        },
        created() {
            this.getProviders()
            this.getKeywords()
            this.getProviderData()

            this.getChartData(this.providerProp.id, this.initBarChart)
            this.getDoughnutChartData(this.providerProp.id, this.initDoughnutChart)
            this.getHeatmapChartData(this.providerProp.id)

            this.provider = this.providerProp

            this.debouncedGetResults = _.debounce(this.getMessages, 500)
        }
    }
</script>

<style scoped>
    .operators-container {
        justify-content: flex-start;
    }

    .filter-buttons a {
        padding: 0 10px;
    }
</style>
