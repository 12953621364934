    import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
    extends: Bar,
    props: ['chartOptions', 'firstColor', 'secondColor', 'chartData'],
    mixins: [reactiveProp],
    mounted () {
        this.renderBarChart()
    },
    watch: {
        chartData: function() {
            this._data._chart.destroy();
            this.renderBarChart()
        }
    },
    methods: {
        renderBarChart() {
            let chartElement = this.$refs.canvas
            let ctx = chartElement.getContext('2d')
            let gradientFill = ctx.createLinearGradient(180, 0, 0, 0);
            gradientFill.addColorStop(0, this.firstColor);
            gradientFill.addColorStop(1, this.secondColor);

            if (this.chartData.datasets[0])
                this.chartData.datasets[0].backgroundColor = gradientFill

            this.renderChart(this.chartData, this.chartOptions);
        }
    }
}