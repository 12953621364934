<template>
    <date-range-picker
            opens="left"
            v-model="dateRange"
            :locale-data="{ firstDay: 1, format: 'DD.MM.YYYY' }"
            @update="emitChange">
    </date-range-picker>
</template>

<script>
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    export default {
        name: "DatePicker",
        components: {DateRangePicker},
        props: ['value'],
        data: function () {
            return {
                dateRange: this.value
            }
        },
        methods: {
            emitChange(dateRange) {
                dateRange.startDate = moment(dateRange.startDate).format('YYYY-MM-DD')
                dateRange.endDate = moment(dateRange.endDate).format('YYYY-MM-DD')
                this.$emit('input', dateRange)
                this.$emit('updateDateRange')
            }
        }
    }
</script>

<style scoped>
    .vue-daterange-picker >>> .form-control {
        height: 32px;
        box-shadow: 0 2px 3px #0000000D;
        font: Normal 13px/20px Source Sans Pro;
    }
</style>