export default {
    computed: {
        dateBetweenFilter() {
            if (this.myDate.startDate && this.myDate.endDate) {
                return `&filter[date_between]=${this.myDate.startDate},${this.myDate.endDate}`
            }

            return ''
        },
        dateBetweenCustomFilter() {
            if (this.myDate.startDate && this.myDate.endDate) {
                console.log("DATES", this.myDate)
                return `&filter[date_between_custom]=${this.myDate.startDate},${this.myDate.endDate}`
            }

            return ''
        },
        dateBetweenTodayFilter() {
            // if (this.todayDate.startDate && this.todayDate.endDate) {
            //     return `&filter[date_between]=${this.todayDate.startDate},${this.todayDate.endDate}`
            // }
            //
            // return ''
        },
        keywordFilter() {
            if (this.keyword) {
                return `&filter[keyword_id]=${this.keyword.id}`
            }

            return ''
        },
        companyFilter() {
            if (this.company) {
                let companyId = this.company.id ? this.company.id : this.company
                return `&filter[company_id]=${companyId}`
            }

            return ''
        },
        statusFilter() {
            if (this.status) {
                return `&filter[status]=${this.status.name}`
            }

            return ''
        },
        statusIdFilter() {
            if (this.status) {
                return `&filter[status_id]=${this.status.value}`
            }
            return ''
        },
        providerFilter() {
            if (this.provider) {
                return `&filter[provider_id]=${this.provider.id}`
            }

            return ''
        },
        fromFilter() {
            if (this.number) {
                return `&filter[from]=${this.number}`
            }

            return ''
        },
        notGeneratedFilter() {
            if (this.notGenerated) {
                return `&filter[not_generated]=OK`
            }

            return ''
        }
    }
}
