<template>
    <div class="dashboard-wrapper">
        <div class="label-row">
            <div class="filter-buttons">
                <h4 class="big-title">{{ number }}</h4>
                <div class="refresh-button" v-if="isBanned">
                    <button class="btn btn-outline-secondary" @click="unbanNumber">Unban</button>
                </div>
            </div>
            <div class="filter-buttons">
                <a href="" @click="printWindow()">Print</a>
            </div>
        </div>

        <div v-if="packages.length">
            <hr>
            <p>Manually send sms to customer</p>
            <div style="display: flex">
                <custom-dropdown
                        name="status"
                        :options="packages"
                        v-model="package"
                ></custom-dropdown>
                <button @click="sendSms" style="z-index: 100; margin-left: 5px" class="btn btn-outline-danger">Send Sms</button>
            </div>
            <p style="margin-top: 10px" v-if="typeof package.name !== 'undefined'"><strong>SMS Preview:</strong> Molimo da jos jednom potvrdite produzenje sa odgovorom: {{package.name}}</p>
        </div>

        <customer-subscriptions :pagination="pagination" @refreshTable="getNumberSubscriptionDetails" @pageChanged="getNumberSubscriptionDetails" @generateCode="generateCode">
            <custom-dropdown
                name="status"
                :options="statusFilterOptions"
                v-model="status"
                @changedSelection="getNumberSubscriptionDetails"
            ></custom-dropdown>

            <date-picker v-model="myDate" @updateDateRange="updateValues"></date-picker>
        </customer-subscriptions>

        <customer-log-history :pagination="logs">

        </customer-log-history>
    </div>
</template>

<script>
    import filters from "../../mixins/filters";
    import CustomerSubscriptions from '../../components/Dashboard/Common/CustomerSubscriptions'

    export default {
        name: "SubscriptionNumberDetails",
        props: ['number', 'company', 'banned'],
        components: {
            'customer-subscriptions': CustomerSubscriptions
        },
        mixins: [filters],
        data() {
            return {
                packages: [],
                package: {},
                logs: [],
                details: null,
                pagination: {},
                sortBy: 'created_at',
                direction: '-', // - is for desc, empty is for asc order
                currentPeriod: '',
                status: '',
                statusFilterOptions: [
                    null, // option for All
                    { value: '1', name: 'Active'},
                    { value: '2', name: 'Completed'},
                    { value: '3', name: 'Canceled'},
                    { value: '4', name: 'Failed'}
                ],
                myDate: {
                    startDate: moment().startOf('month').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                },
                isBanned: false
            }
        },
        methods: {
            sendSms() {
                if (confirm('Are you sure?')) {
                    axios.get('/customer-send-manual-sms', {
                        params: {
                            to: this.number,
                            text: this.package.name
                        }
                    }).then(res => {
                        alert("Sms sent successfully!")
                    }).catch(err => {
                        alert("Error sending sms!")
                    })
                }
            },
            getPackages() {
                axios.get(`/keywords/raw?filter[company_id]=${this.company}`)
                    .then(response => {
                        this.packages = response.data
                    });
            },
            getCustomerLogHistory() {
                axios.get('/customer-log-history', {
                    params: {
                        phone: this.number
                    }
                }).then(res => {
                        this.logs = res.data
                    })
                    .catch(err => {
                    })
            },
            updateValues() {
                this.currentPeriod = `${this.myDate.startDate} - ${this.myDate.endDate}`
                this.getNumberSubscriptionDetails()
            },
            printWindow() {
                window.print()
            },
            unbanNumber() {
                this.$http.get(`/unban/${this.number}`)
                    .then(response => {
                        this.$snotify.success(response.data.status)
                        this.isBanned = false
                    });
            }
        },
        mounted() {
            this.getNumberSubscriptionDetails()
        },
        created() {
            this.getPackages()
            this.getCustomerLogHistory()
            this.isBanned = this.banned
        }
    }
</script>

<style scoped>

</style>
