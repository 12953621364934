export default {
    // Template needs to be created for each company as usage and requirements
    // for each company are different
    // All companies and templates should be mapped here
    data() {
        return {
            companies: [
                {
                    id: 1,
                    name: "OLX",
                    template: 'olx-template'
                },
                {
                    id: 3,
                    name: "BIHAMK",
                    template: 'bihamk-template'
                }
            ]
        }
    },
    computed: {
        getTemplate() {
            let template = this.companies[0].template;

            this.companies.forEach((item) => {
                if (item.id === this.company) {
                    template = item.template
                }
            })

            return template;
        }
    }
}
