<template>
    <div>
        <tooltip/>
        <vue-snotify></vue-snotify>

        <div class="label-row mt-5">
            <div class="refresh-button">
                <h4 class="big-title">Subscriptions</h4>
                <button class="btn btn-outline-secondary" @click="$emit('refreshTable')">Refresh</button>
            </div>

            <div class="filter-buttons">
                <slot></slot>
            </div>
        </div>

        <table class="messages-table">
            <tr>
                <th></th>
                <th>Package</th>
                <th>Status</th>
                <th>Generated code</th>
                <th>Amount</th>
                <th>Activated</th>
                <th>Action</th>
            </tr>
            <tr v-if="!pagination.data">
                <th colspan="6">
                    <span class="loader-icon"></span>
                </th>
            </tr>
            <template v-if="pagination.data" v-for="(message, index) in pagination.data">
                <tr @click="toggleAdditional(message)" >
                    <td><i :class="opened.includes(message.id) ? 'fas fa-chevron-down' : 'fas fa-chevron-right'"></i></td>
                    <td>{{ message.package.keyword.name }}</td>
<!--                    <td>{{ message.status.status }}</td>-->
                  <td :style="{color: message.status.color}">{{ message.status.status }}</td>
                  <td>{{ message.code }}</td>
                    <td>{{ message.package.amount }} KM</td>
                    <td>{{ message.created_at }}</td>
                    <td>
                        <button v-if="message.status.status === 'Active'" class="btn btn-sm btn-outline-danger" @click="stopSubscription(message.id)">Stop</button>
                        <button v-if="message.status.status === 'Canceled' || message.status.status === 'Failed'" class="btn btn-sm btn-outline-success" @click="activateSubscription(message.id)">Start</button>
                    </td>
                </tr>
                <tr v-if="opened.includes(message.id)">
                    <td></td>
                    <td class="pt-0" colspan="6">
                        <table class="messages-table" style="width: 100%;">
                            <tr>
                                <th>No.</th>
                                <th>Payment Date</th>
                                <th>Paid On</th>
                                <th>Status</th>
                                <th>Amount</th>
                            </tr>
                            <tr v-if="message.payments" v-for="(payment, index) in message.payments">
                                <td>{{ index + 1 }}</td>
                                <td>{{ payment.payment_date }}</td>
                                <td>{{ payment.paid_on ? payment.paid_on : 'Not paid' }}</td>
<!--                                <td>{{ payment.status.status }}</td>-->
                              <td :style="{color: message.status.color}">{{ message.status.status }}</td>
                              <td>{{ payment.rate.amount }}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </template>
        </table>

        <pagination :data="pagination" @pagination-change-page="$emit('pageChanged', $event)" align="center" :limit="3"></pagination>
    </div>
</template>

<script>
    export default {
        name: "CustomerSubscriptions",
        props: ['pagination'],
        data() {
            return {
                opened: []
            }
        },
        methods: {
            activateSubscription(id) {
                if (confirm('Are you sure? This will start canceled subscription!')) {

                    axios.get(`/bihamk-activate-subscription/${id}`)
                        .then(res => {
                            this.$emit('pageChanged', this.pagination.current_page)
                        })
                        .catch(error => {
                        })
                }
            },
            stopSubscription(id) {
                if (confirm('Are you sure? This will stop customer subscription!')) {
                    axios.get(`/bihamk-stop-subscription/${id}`)
                        .then(res => {
                            this.$emit('pageChanged', this.pagination.current_page)
                        })
                        .catch(error => {
                        })
                }
            },
            toggleAdditional({id}) {
                const index = this.opened.indexOf(id);
                if (index > -1) {
                    this.opened.splice(index, 1)
                } else {
                    this.opened.push(id)
                }
            }
        },
        created() {
        }
    }
</script>

<style scoped>
    .refresh-button {
        display: flex;
    }

    .refresh-button button {
        width: 130px;
        height: 32px;
        padding: 0;
        margin-left: 10px;
        box-shadow: none;
    }

    .filter-buttons {
        display: flex;
        justify-content: space-around;
    }

    .filter-buttons div {
        padding: 0 10px;
    }

    table {
        width: 100%;
    }

    th {
        font-family: Roboto, Bold, sans-serif;
        background: #F5F6FA;
        padding: 15px 20px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        text-align: left;
        letter-spacing: 0.41px;
        color: #87878A;
        opacity: 1;
    }

    td {
        padding: 20px 20px;
        font: Normal 15px/20px Source Sans Pro;
        color: #4D4F5C;
        cursor: pointer;
    }

    tr td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .pagination {
        margin-top: 20px;
    }

    .pagination >>> .page-item .page-link {
        color: #87878A;
        box-shadow: none;
    }

    .pagination >>> .page-item.active .page-link {
        background-color: #ebecf0;
        border: 1px solid #dee2e6;
    }
</style>
