<template>
    <div>
        <span id="tooltip-span"></span>
        <div class="pie-chart-card pie-chart-card-loading" v-if="loading">
            <span class="loader-icon"></span>
        </div>

        <div class="pie-chart-card" v-else-if="provider && providerChart && providerChart.labels.length">
            <div class="left-side">
                <span class="title">{{ title ? title : 'SMS' }}</span>
            </div>
            <pie-chart :chart-data="providerChart" :options="doughnutChartOptions"></pie-chart>
        </div>

        <div class="pie-chart-card empty" v-else>
            No records in given period
        </div>
    </div>
</template>

<script>
    import chartOptions from '../../../mixins/chart.js'
    export default {
        name: "PieChartCard",
        props: ['provider', 'providerChart', 'loading', 'title'],
        mixins: [chartOptions]
    }
</script>

<style scoped>
    #tooltip-span {
        display: none;
    }

    .pie-chart-card {
        margin-left: 30px;
        margin-right: 30px;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 450px;
        height: 178px;
        padding: 16px;
        box-shadow: 0 2px 10px #00000033;
        border-radius: 10px;
    }

    .pie-chart-card.empty {
        justify-content: center;
        align-items: center;
        font-size: 17px;
    }

    .pie-chart-card >>> #doughnut-chart {
        height: 120px!important;
    }

    .pie-chart-card-loading {
        align-items: center;
        justify-content: center;
    }

    .loader-icon {
        top: 0;
        left: 0;
    }
</style>
