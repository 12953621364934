/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import axios from 'axios'
Vue.prototype.$http = axios;

window.moment = require('moment/moment');

import methods from './mixins/methods'
Vue.mixin(methods);

import VueCharts from 'vue-chartjs'
Vue.use(VueCharts);

import VueFormGenerator from 'vue-form-generator'
import 'vue-form-generator/dist/vfg.css'
Vue.use(VueFormGenerator)

import Snotify from 'vue-snotify'
Vue.use(Snotify, {
    toast: {
        timeout: 2000,
        showProgressBar: false,
        pauseOnHover: false
    }
});

import Popover  from 'vue-js-popover'

Vue.use(Popover, { tooltip: true })

import VueSweetalert2 from 'vue-sweetalert2'
Vue.use(VueSweetalert2);

import VuePluralize from 'vue-pluralize'
Vue.use(VuePluralize)

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('pagination', require('laravel-vue-pagination'));

Vue.component('dashboard', require('./components/Dashboard/Dashboard.vue').default);
Vue.component('olx-template', require('./components/Dashboard/OlxTemplate.vue').default);
Vue.component('bihamk-template', require('./components/Dashboard/BihamkTemplate.vue').default);
Vue.component('provider-details', require('./components/Dashboard/ProviderDetails.vue').default);
Vue.component('number-details', require('./components/Dashboard/NumberDetails.vue').default);
Vue.component('subscription-number-details', require('./components/Dashboard/SubscriptionNumberDetails.vue').default);
Vue.component('report-details', require('./components/Dashboard/ReportDetails.vue').default);
Vue.component('bihamk-report-details', require('./components/Dashboard/BihamkReportDetails.vue').default);
Vue.component('not-generated', require('./components/Dashboard/NotGenerated.vue').default);
Vue.component('number-spending', require('./components/Dashboard/NumberSpending.vue').default);
Vue.component('provider-errors', require('./components/Dashboard/ProviderErrors.vue').default);
Vue.component('error-numbers', require('./components/Dashboard/ErrorNumbers.vue').default);

Vue.component('messages-table', require('./components/Dashboard/Common/MessagesTable.vue').default);
Vue.component('operator-card', require('./components/Dashboard/Common/OperatorCard.vue').default);
Vue.component('pie-chart-card', require('./components/Dashboard/Common/PieChartCard.vue').default);
Vue.component('custom-dropdown', require('./components/Dashboard/Common/CustomDropdown.vue').default);
Vue.component('stats-card', require('./components/Dashboard/Common/StatsCard.vue').default);
Vue.component('date-picker', require('./components/Dashboard/Common/DatePicker.vue').default);
Vue.component('customer-card', require('./components/Dashboard/Common/CustomerCard').default);
Vue.component('payments-table', require('./components/Dashboard/Common/PaymentsTable.vue').default);
Vue.component('active-subscribers', require('./components/Dashboard/ActiveSubscribers.vue').default);
Vue.component('subscriptions-table', require('./components/Dashboard/Common/SubscriptionsTable.vue').default);
Vue.component('customer-help', require('./components/Dashboard/CustomerHelp').default);
Vue.component('customer-help-table', require('./components/Dashboard/Common/CustomerHelpTable').default);
Vue.component('customers', require('./components/Dashboard/Customers').default);
Vue.component('customers-table', require('./components/Dashboard/Common/CustomersTable.vue').default);
Vue.component('customer-log-history', require('./components/Dashboard/Common/CustomerLogHistory').default);

Vue.component('pie-chart', require('./components/DoughnutChart.js').default);
Vue.component('bar-chart', require('./components/BarChart.js').default);

Vue.component('content-table', require('./components/ContentTable.vue').default);
Vue.component('content-form', require('./components/ContentForm.vue').default);

Vue.component('company-index', require('./components/Company/Index.vue').default);
Vue.component('company-form', require('./components/Company/Form.vue').default);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
