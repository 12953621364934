<template>
    <div>
        <tooltip/>
        <vue-snotify></vue-snotify>

        <div class="label-row mt-5">
            <div class="refresh-button">
<!--                <h4 class="big-title">Customers</h4>-->
<!--                <button class="btn btn-outline-secondary" @click="$emit('refreshTable')">Refresh</button>-->
            </div>

            <div class="filter-buttons">
                <slot></slot>
            </div>
        </div>

        <table class="messages-table">

            <tr>
                <th></th>
                <th>Phone Number</th>
                <th>Failed Attempts</th>
                <th>Action</th>
            </tr>
            <tr v-if="!pagination.data">
                <th colspan="6">
                    <span class="loader-icon"></span>
                </th>
            </tr>
            <template v-if="pagination.data" v-for="(customer, index) in pagination.data">
                <tr @click="toggleAdditional(customer)">
                    <td><i :class="opened.includes(customer.id) ? 'fas fa-chevron-down' : 'fas fa-chevron-right'"></i></td>
                    <td>{{ customer.phone }}</td>
                    <td>{{ customer.failed_attempts }}</td>
                    <td><button class="btn btn-sm btn-outline-success" @click="resolveTicket(customer.id)">Mark as resolved</button></td>
                </tr>
                <tr v-if="opened.includes(customer.id)">
                    <td></td>
                    <td class="pt-0" colspan="3">
                        <table class="messages-table" style="width: 100%;">
                            <tr>
                                <th>No.</th>
                                <th>Message</th>
                                <th>Sent at</th>
                            </tr>
                            <tr v-if="customer.replies" v-for="(reply, index) in customer.replies">
                                <td>{{ index + 1 }}</td>
                                <td>{{ reply.text }}</td>
                                <td>{{ reply.created_at}}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </template>

        </table>

        <pagination :data="pagination" @pagination-change-page="$emit('pageChanged', $event)" align="center" :limit="3"></pagination>
    </div>
</template>

<script>
    export default {
        name: "CustomerHelpTable",
        props: ['pagination'],
        data() {
            return {
                opened: []
            }
        },
        methods: {
            resolveTicket(id) {
                if (confirm('Are you sure?')) {
                    axios.get(`/resolve-customer-need/${id}`)
                        .then(res => {
                            this.$emit('refreshTable')
                        })
                        .catch(error => {
                        })
                }
            },
            toggleAdditional({id}) {
                const index = this.opened.indexOf(id);
                if (index > -1) {
                    this.opened.splice(index, 1)
                } else {
                    this.opened.push(id)
                }
            }
        }
    }
</script>

<style scoped>
    .refresh-button {
        display: flex;
    }

    .refresh-button button {
        width: 130px;
        height: 32px;
        padding: 0;
        margin-left: 10px;
        box-shadow: none;
    }

    .filter-buttons {
        display: flex;
        justify-content: space-around;
    }

    .filter-buttons div {
        padding: 0 10px;
    }

    table {
        width: 100%;
    }

    th {
        font-family: Roboto, Bold, sans-serif;
        background: #F5F6FA;
        padding: 15px 20px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        text-align: left;
        letter-spacing: 0.41px;
        color: #87878A;
        opacity: 1;
    }

    td {
        padding: 20px 20px;
        font: Normal 15px/20px Source Sans Pro;
        color: #4D4F5C;
        cursor: pointer;
    }

    tr td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .pagination {
        margin-top: 20px;
    }

    .pagination >>> .page-item .page-link {
        color: #87878A;
        box-shadow: none;
    }

    .pagination >>> .page-item.active .page-link {
        background-color: #ebecf0;
        border: 1px solid #dee2e6;
    }
</style>

