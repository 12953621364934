<template>
    <div class="dashboard-wrapper">
        <div class="label-row">
            <div class="filter-buttons">
                <h4 class="big-title">Provider errors</h4>

                <span class="current-period">{{ currentPeriod }}</span>
            </div>
            <div class="filter-buttons">
                <a href="" @click="printWindow()">Print</a>
            </div>
        </div>

        <div class="chart" v-if="stackedChart && stackedChart.labels && stackedChart.labels[0]">
            <bar-chart
                    :chart-data="stackedChart"
                    :chart-options="stackedChartOptions"
                    first-color="#FE8300"
                    second-color="#FEB55578"></bar-chart>
        </div>

        <div>
            <div class="label-row mt-5">
                <div class="refresh-button">
                    <h4 class="big-title">All errors</h4>
                    <button class="btn btn-outline-secondary" @click="getData">Refresh</button>
                </div>

                <div class="filter-buttons">
<!--                    <label>-->
<!--                        <input class="form-control" type="text" v-model="number" @input="debouncedGetResults" placeholder="Search number...">-->
<!--                    </label>-->

                    <custom-dropdown
                            name="provider"
                            :options="providers"
                            v-model="provider"
                            @changedSelection="getData"
                    ></custom-dropdown>

                    <date-picker v-model="myDate" @updateDateRange="updateValues"></date-picker>
                </div>
            </div>

            <table class="messages-table">
                <tr>
                    <th>Error</th>
                    <th>Provider</th>
                    <th>Code</th>
                    <th>Count</th>
                    <th>Percentage</th>
                </tr>
                <tr v-if="!pagination.data">
                    <th colspan="6">
                        <span class="loader-icon"></span>
                    </th>
                </tr>
                <tr @click="seeNumbers(error)" v-if="pagination.data" v-for="(error, index) in pagination.data">
                    <template v-if="error">
                        <td>{{ error.description }}</td>
                        <td><img :src="providerLogo(error.provider_id)"></td>
                        <td>{{ error.code }}</td>
                        <td>{{ error.count }}</td>
                        <td>{{ error.percentage }} %</td>
                    </template>
                </tr>

                <tr>
                    <th>Total</th>
                    <th></th>
                    <th></th>
                    <th>{{ errorsCount }}</th>
                    <th>{{ errorsCount ? '100%' : '' }}</th>
                </tr>
            </table>

            <pagination :data="pagination" @pagination-change-page="getData" align="center" :limit="3"></pagination>
        </div>
    </div>
</template>

<script>
    import filters from '../../mixins/filters.js'
    import chartOptions from '../../mixins/chart.js'
    export default {
        props: ['company'],
        mixins: [filters, chartOptions],
        data: function () {
            return {
                details: null,
                pagination: {},
                sortBy: 'created_at',
                direction: '-', // - is for desc, empty is for asc order

                requestTypeId: 2, // sms that are sent from mobile to our application

                relations: ['keyword'],

                providers: [],
                keywords: [],

                // Filters
                provider: {
                    id: 1,
                    name: 'BHTelecom'
                },
                number: '',
                keyword: null,
                currentPeriod: '',
                status: '',
                statusFilterOptions: [
                    null, // option for All
                    { value: 'OK', name: 'OK'},
                    { value: 'NOT OK', name: 'NOT OK'}
                ],

                // date range picker
                myDate: {
                    startDate: moment().startOf('month').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                },

                errorsCount: 0,

                stackedChart: null,
            }
        },
        methods: {
            updateValues() {
                this.currentPeriod = moment(this.myDate.startDate).format('DD.MM.YYYY') + ' - ' +  moment(this.myDate.endDate).format('DD.MM.YYYY')
                this.getData()
            },
            unbanNumber() {
                this.$http.get(`/unban/${this.number}`)
                    .then(response => {
                        this.$snotify.success(response.data.status)
                        this.isBanned = false
                    });
            },
            getData(page = 1) {
                if (typeof page !== 'number')
                    page = 1

                this.$http.get(`/dashboard/${this.company}/provider-errors/data?page=${page}` +
                    `&filter[company_id]=${this.company}` +
                    `${this.providerFilter}` +
                    `${this.fromFilter}` +
                    `${this.dateBetweenFilter}`)
                    .then(response => {
                        console.log(response.data.pagination)
                        console.log(response.data.chart)
                        this.pagination = response.data.pagination
                        this.initStackedChart(response.data.chart)

                        if (this.pagination.data[Object.keys(this.pagination.data)[0]])
                            this.errorsCount = this.pagination.data[Object.keys(this.pagination.data)[0]].total_count
                    });
            },
            seeNumbers(error) {
                window.location.href = `/dashboard/${this.company}/error-numbers?description=${error.description}`
            },
            initStackedChart(data) {
                let stack = 1
                let days = []
                let colors = ['#C2FF6E', '#FFCAA6', '#B6F3FF', '#FF4955', '#FFEC19', '#B035FF', '#7C827C']

                let dataSets = Object.values(data).map(error => {
                    days = error.days
                    return {
                        label: error.code ? `Code ${error.code}` : error.description.substr(0, 30),
                        backgroundColor: colors[stack - 2],
                        data: Object.values(error.data),
                        stack: stack++
                    }
                })

                this.stackedChart = {
                    labels: Object.values(days),
                    datasets: dataSets
                }
            }
        },
        mounted() {
            this.getData()

        },
        created() {
            this.getProviders()
            this.debouncedGetResults = _.debounce(this.getData, 500)
            this.currentPeriod = moment(this.myDate.startDate).format('DD.MM.YYYY') + ' - ' +  moment(this.myDate.endDate).format('DD.MM.YYYY')
        }
    }
</script>

<style scoped>
    .refresh-button {
        display: flex;
    }

    .refresh-button button {
        width: 130px;
        height: 32px;
        padding: 0;
        margin-left: 10px;
        box-shadow: none;
    }

    .filter-buttons {
        display: flex;
        justify-content: space-around;
    }

    .filter-buttons div {
        padding: 0 10px;
    }

    table {
        width: 100%;
    }

    th {
        font-family: Roboto, Bold, sans-serif;
        background: #F5F6FA;
        padding: 15px 20px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        text-align: left;
        letter-spacing: 0.41px;
        color: #87878A;
        opacity: 1;
    }

    td {
        padding: 20px 20px;
        font: Normal 15px/20px Source Sans Pro;
        color: #4D4F5C;
        cursor: pointer;
    }

    tr td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .pagination {
        margin-top: 20px;
    }

    .pagination >>> .page-item .page-link {
        color: #87878A;
        box-shadow: none;
    }

    .pagination >>> .page-item.active .page-link {
        background-color: #ebecf0;
        border: 1px solid #dee2e6;
    }

    .chart {
        /*display: flex;*/
        justify-content: center;
        width: 100%;
    }

    .chart >>> #bar-chart {
        width: 100%!important;
        height: 400px!important;
    }
</style>