<template>
    <div class="dashboard-wrapper">
        <messages-table :pagination="pagination" @refreshTable="getMessages" @numberHistory="numberHistory" @generateCode="generateCode">
            <label>
                <input class="form-control" type="text" v-model="number" @input="debouncedGetResults" placeholder="Search number...">
            </label>
            <custom-dropdown
                    name="status"
                    :options="statusFilterOptions"
                    v-model="status"
                    @changedSelection="getMessages"
            ></custom-dropdown>

            <custom-dropdown
                    name="keyword"
                    :options="keywords"
                    v-model="keyword"
                    @changedSelection="getMessages"
            ></custom-dropdown>

            <date-picker v-model="myDate" @updateDateRange="updateValues"></date-picker>
        </messages-table>
    </div>
</template>

<script>
    import filters from '../../mixins/filters.js'
    export default {
        name: "NotGenerated",
        mixins: [filters],
        props: ['company'],
        data: function () {
            return {
                pagination: {},
                sortBy: 'created_at',
                direction: '-', // - is for desc, empty is for asc order

                requestTypeId: 2, // sms that are sent from mobile to our application

                // used to get only messages that has no generated code
                notGenerated: true,

                relations: ['keyword'],

                providers: [],
                keywords: [],

                // Filters
                provider: null,
                keyword: null,
                currentPeriod: '',
                status: '',
                statusFilterOptions: [
                    null, // option for All
                    { value: 'OK', name: 'OK'},
                    { value: 'NOT OK', name: 'NOT OK'}
                ],
                number: '',

                // date range picker
                myDate: {
                    startDate: moment().startOf('month').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                },
            }
        },
        methods: {
            updateValues() {
                // this.currentPeriod = `${this.myDate.startDate} - ${this.myDate.endDate}`
                this.getMessages()
            },
        },
        mounted() {
            this.getMessages()
        },
        created() {
            this.getProviders()
            this.getKeywords()

            this.debouncedGetResults = _.debounce(this.getMessages, 500)
        }
    }
</script>

<style scoped>

</style>