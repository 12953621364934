<template>
    <div>
        <tooltip/>
        <vue-snotify></vue-snotify>


        <div class="label-row mt-5">
            <div class="refresh-button">
                <h4 class="big-title">Log History</h4>
                <!--                <button class="btn btn-outline-secondary" @click="$emit('refreshTable')">Refresh</button>-->
            </div>

            <div class="filter-buttons">
                <slot></slot>
            </div>
        </div>

        <table class="messages-table">
            <tr>
                <th>#</th>
                <th>Action</th>
                <th>Created At</th>
            </tr>
            <tr v-if="!pagination">
                <th colspan="6">
                    <span class="loader-icon"></span>
                </th>
            </tr>
            <tr v-if="pagination" v-for="(customer, index) in pagination" :key="customer.id">
                <td>{{index+1}}</td>
                <td>{{customer.action}}</td>
                <td>{{customer.created_at}}</td>
            </tr>
        </table>

    </div>
</template>

<script>
    export default {
        name: "CustomerLogHistory",
        props: ['pagination']
    }
</script>

<style scoped>
    .refresh-button {
        display: flex;
    }

    .refresh-button button {
        width: 130px;
        height: 32px;
        padding: 0;
        margin-left: 10px;
        box-shadow: none;
    }

    .filter-buttons {
        display: flex;
        justify-content: space-around;
    }

    .filter-buttons div {
        padding: 0 10px;
    }

    table {
        width: 100%;
    }

    th {
        font-family: Roboto, Bold, sans-serif;
        background: #F5F6FA;
        padding: 15px 20px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        text-align: left;
        letter-spacing: 0.41px;
        color: #87878A;
        opacity: 1;
    }

    td {
        padding: 20px 20px;
        font: Normal 15px/20px Source Sans Pro;
        color: #4D4F5C;
        cursor: pointer;
    }

    tr td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .pagination {
        margin-top: 20px;
    }

    .pagination >>> .page-item .page-link {
        color: #87878A;
        box-shadow: none;
    }

    .pagination >>> .page-item.active .page-link {
        background-color: #ebecf0;
        border: 1px solid #dee2e6;
    }

    .status-column {
        color: #419AFF;
    }
</style>