<template>
    <div>
        <div class="operator-card operator-card-loading" v-if="loading">
            <span class="loader-icon"></span>
        </div>

        <div class="operator-card" v-else-if="provider && provider.smscount">
            <span id="tooltip-span"></span>

            <div class="card-row">
                <div class="left-side">
                    <span class="title">{{ providerName }}</span>
                    <img :src="logo" alt="logo" v-if="logo">
                </div>
                <span class="right-side">{{ Number(provider.amount) }} KM</span>
            </div>
            <div class="card-row">
                <div class="left-side">
                    <span class="title">Total SMS</span>
                </div>
                <div class="right-side">
                    {{ provider.smscount }}
                </div>
            </div>
            <div class="chart-row">
                <div class="chart" :id="`chart_${provider.provider.id}`" v-if="providerChart">
                    <bar-chart :chart-data="providerChart" :chart-options="barChartOptions" :first-color="firstColor" :second-color="secondColor"></bar-chart>
                </div>
                <span class="percentage" :class="percentageColor(provider.growthPercentage)"><i class="fas" :class="percentageArrow(provider.growthPercentage)"></i> {{ provider.growthPercentage }}%</span>
            </div>
        </div>

        <div class="operator-card" v-else>
            <div class="card-row">
                <div class="left-side">
                    <span class="title">{{ providerName }}</span>
                    <img :src="logo" alt="logo" v-if="logo">
                </div>
            </div>
            <div>
                No records in given period
            </div>
        </div>
    </div>
</template>

<script>
    import chartOptions from '../../../mixins/chart.js'
    export default {
        name: "OperatorCard",
        mixins: [chartOptions],
        props: ['provider', 'providerName', 'providerChart', 'logo', 'firstColor', 'secondColor', 'loading']
    }
</script>

<style scoped>
    #tooltip-span {
        display: none;
        z-index: 1000;
    }

    .operator-card {
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 305px;
        height: 178px;
        padding: 16px;
        box-shadow: 0 2px 10px #00000033;
        border-radius: 10px;
    }

    .card-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .left-side {
        display: flex;
        flex-direction: column;
        line-height: 1.4;
    }

    .right-side {
        display: flex;
        justify-content: center;
        align-items: center;
        font: Bold 22px/28px Source Sans Pro;
        color: #4D4F5C;
    }

    .right-side.retries {
        font-family: Source Sans Pro, sans-serif;
        font-size: 13px;
        color: #4D4F5C;
        font-weight: bold;
    }

    .left-side img {
        margin-right: auto;
    }

    .left-side .month {
        font-family: 'Mosk 600', Nunito, sans-serif;
        font-size: 12px;
        color: #A7A7A7;
    }

    .left-side .title {
        color: #636375;
        font-family: 'Mosk 600', Nunito, sans-serif;
        font-size: 18px;
    }

    .left-side .number {
        font: Normal 16px/25px Source Sans Pro;
        color: #43425D;
    }

    .operator-info img {
        margin-right: auto;
    }

    .sent-sms-label .month {
        font-size: 11px;
        opacity: 0.7;
        font-weight: bold;
    }

    .chart-row {
        display: flex;
        align-content: center;
        justify-content: space-between;
    }

    .chart-row img {
        width: 176px;
    }

    .chart-row .percentage {
        display: flex;
        align-items: flex-end;
        font: Bold 14px/18px Source Sans Pro;
        line-height: 1;
        /*font-size: 14px;*/
    }

    .chart-row .percentage.green {
        color: #96C56E;
    }

    .chart-row .percentage.red {
        color: #F57D78;
    }

    .chart-row .percentage .fa-arrow-up {
        margin:0 5px;
        color: #96C56E;
    }

    .chart-row .percentage .fa-arrow-down {
        margin:0 5px;
        color: #F57D78;
    }

    .chart {
        width: 176px;
    }

    .chart >>> #bar-chart {
        height: 40px!important;
    }

    .operator-card-loading {
        align-items: center;
        justify-content: center;
    }

    .loader-icon {
        top: 0;
        left: 0;
    }
</style>
