<template>
    <component :company="company" :is="getTemplate"></component>
</template>

<script>
    import templates from "../../mixins/templates";

    export default {
        name: "Dashboard",
        props: ['company'],
        mixins: [templates],
    }
</script>

<style scoped>

</style>
